import React from "react";
import footerScss from "../assets/scss/footer.module.scss";
import { Button, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { move } from "../common/utils";

const Footer: React.FC = () => {
  const navigate = useNavigate();

  return (
    <footer className={footerScss.footer}>
      <Stack direction="column" alignItems="center" gap={0}>
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            onClick={() => {
              move(navigate, "/");
            }}
            className={footerScss.button}
          >
            本棚
          </Button>
          <Button
            onClick={() => {
              move(navigate, "/recommended");
            }}
            className={footerScss.button}
          >
            おすすめ
          </Button>
          <Button
            onClick={() => {
              move(navigate, "/search");
            }}
            className={footerScss.button}
          >
            本を探す
          </Button>
        </Stack>
        <Button
          onClick={() => {
            move(navigate, "/account");
          }}
          className={footerScss.button}
        >
          アカウント設定・削除
        </Button>
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            href={`${process.env.PUBLIC_URL}/pdf/terms.pdf`}
            target="_blank"
            className={footerScss.button}
          >
            利用規約
          </Button>
          <Button
            href={`${process.env.PUBLIC_URL}/pdf/privacy-policy.pdf`}
            target="_blank"
            className={footerScss.button}
          >
            プライバシーポリシー
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            href={process.env.REACT_APP_HELP_GUIDE_URL!}
            target="_blank"
            className={footerScss.button}
          >
            ヘルプガイド
          </Button>
          <Button
            onClick={() => {
              window.open(
                `mailto:${process.env.REACT_APP_CONTACT_MAIL_ADDRESS}`,
                "_blank"
              );
            }}
            className={footerScss.button}
          >
            お問い合わせ
          </Button>
        </Stack>

        <span>
          &copy; 2024 - {new Date().getFullYear()} 佐藤智宏 | All Rights
          Reserved
        </span>
      </Stack>
    </footer>
  );
};

export default Footer;
