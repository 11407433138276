import React, { useContext, useEffect, useState } from "react";
import commonScss from "../../assets/scss/common.module.scss";
import dialogScss from "../../assets/scss/dialog.module.scss";
import { Message } from "../../common/model";
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { getDisplayedDatetime, handleError } from "../../common/utils";
import getAxiosInstance from "../../common/Axios";
import { Context } from "../../App";

interface Props {
  messages: Message[];
  index: number | undefined;
  setIndex: (value: number | undefined) => void;
}

const MessageDialog: React.FC<Props> = (props) => {
  const { messages, index, setIndex } = props;
  const { loadMessages } = useContext(Context);

  const [displayedMessage, setDisplayedMessage] = useState<
    Message | undefined
  >();

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    const message = index !== undefined ? messages[index] : undefined;
    setDisplayedMessage(message);
    if (message) {
      if (!message.isRead && !isLoaded) {
        (async () => {
          const axiosInstance = await getAxiosInstance();
          axiosInstance
            .put("/messages", {
              dataId: message.dataId,
              isRead: true,
            })
            .then(() => {
              loadMessages();
              setIsLoaded(true);
            })
            .catch((error) => {
              handleError(error);
            });
        })();
      }
    } else {
      setIsLoaded(false);
    }
  }, [messages, index, loadMessages, isLoaded]);

  return (
    <Dialog open={!!displayedMessage && index !== undefined}>
      {!!displayedMessage && index !== undefined && (
        <>
          <DialogTitle className={commonScss.textCenter}>
            {displayedMessage.subject}
          </DialogTitle>
          <IconButton
            onClick={() => {
              setIndex(undefined);
            }}
            className={dialogScss.close}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Stack direction="column" alignItems="center">
              {displayedMessage.isImportant && (
                <Chip label="重要" color="primary" variant="outlined" />
              )}
              <div className={commonScss.preWrap}>
                {displayedMessage.webMessage}
              </div>
              <br />
              {getDisplayedDatetime(displayedMessage.createdAt)}
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setIndex(index + 1);
              }}
              disabled={index + 1 >= messages.length}
            >
              前へ
            </Button>
            <Button
              onClick={() => {
                setIndex(index - 1);
              }}
              disabled={index <= 0}
            >
              次へ
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default MessageDialog;
