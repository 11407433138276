import { Button, FormControlLabel, FormGroup, Switch } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../App";
import { deleteUser } from "aws-amplify/auth";
import { useNavigate } from "react-router-dom";
import { handleError, move } from "../../common/utils";

const DeleteAccount: React.FC = () => {
  const { addLoadingProcess, deleteLoadingProcess } = useContext(Context);
  const navigate = useNavigate();
  const [understood1, setUnderstood1] = useState(false);
  const [understood2, setUnderstood2] = useState(false);

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Switch
              checked={understood1}
              onChange={(_, checked) => {
                setUnderstood1(checked);
              }}
            />
          }
          label="アカウント削除後、アカウント情報を復活させることはできないことを理解しました。"
          required
        />
        <FormControlLabel
          control={
            <Switch
              checked={understood2}
              onChange={(_, checked) => {
                setUnderstood2(checked);
              }}
            />
          }
          label="アカウント削除後、登録した本の情報を削除することはできないことを理解しました(本の情報の削除が必要な場合には、アカウント削除前に実施してください)。"
          required
        />
        <Button
          color="error"
          disabled={!understood1 || !understood2}
          onClick={() => {
            if (
              window.confirm(
                "注意事項を確認した上で、アカウント削除を行いますか?"
              )
            ) {
              const processId = addLoadingProcess();
              deleteUser()
                .then(() => {
                  move(navigate, "/");
                  window.location.reload();
                })
                .catch((error) => {
                  handleError(error);
                })
                .finally(() => {
                  deleteLoadingProcess(processId);
                });
            }
          }}
        >
          アカウント削除
        </Button>
      </FormGroup>
    </>
  );
};

export default DeleteAccount;
