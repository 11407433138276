import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { updateUserAttribute, deleteUser } from "aws-amplify/auth";
import { handleError, move } from "../common/utils";
import commonScss from "../assets/scss/common.module.scss";
import { useNavigate } from "react-router-dom";

const REACT_APP_ACKNOWLEDGEMENT: React.FC = () => {
  const {
    addLoadingProcess,
    deleteLoadingProcess,
    userAttributes,
    loadUserAttributes,
  } = useContext(Context);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    setOpen(
      !!userAttributes &&
        userAttributes["custom:acknowledgement"] !==
          process.env.REACT_APP_ACKNOWLEDGEMENT
    );
  }, [userAttributes]);

  return (
    <Dialog open={open} fullWidth>
      <>
        <DialogTitle className={commonScss.textCenter}>
          利用規約及びプライバシーポリシーの確認・同意
        </DialogTitle>
        <DialogContent>
          <Stack direction="column" alignItems="center" gap={8}>
            <Stack direction="column" alignItems="center" gap={2}>
              <span>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.PUBLIC_URL}/pdf/terms.pdf`}
                >
                  利用規約
                </a>
                及び
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.PUBLIC_URL}/pdf/privacy-policy.pdf`}
                >
                  プライバシーポリシー
                </a>
                を確認し、同意してください
              </span>
              <Button
                variant="contained"
                onClick={() => {
                  const processId = addLoadingProcess();
                  updateUserAttribute({
                    userAttribute: {
                      attributeKey: "custom:acknowledgement",
                      value: process.env.REACT_APP_ACKNOWLEDGEMENT!,
                    },
                  })
                    .catch((error) => {
                      handleError(error);
                    })
                    .finally(() => {
                      deleteLoadingProcess(processId);
                      loadUserAttributes();
                    });
                }}
              >
                同意
              </Button>
            </Stack>
            <Button
              color="error"
              onClick={() => {
                if (
                  window.confirm(
                    "アカウント削除後、アカウント情報を復活させることはできません。アカウント削除を行いますか?"
                  )
                ) {
                  const processId = addLoadingProcess();
                  deleteUser()
                    .then(() => {
                      move(navigate, "/");
                      window.location.reload();
                    })
                    .catch((error) => {
                      handleError(error);
                    })
                    .finally(() => {
                      deleteLoadingProcess(processId);
                    });
                }
              }}
            >
              サービス利用をやめ、アカウントを削除する
            </Button>
          </Stack>
        </DialogContent>
      </>
    </Dialog>
  );
};

export default REACT_APP_ACKNOWLEDGEMENT;
