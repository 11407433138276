import React, { useContext, useEffect, useState } from "react";
import logo from "../assets/images/logo.svg";
import headerScss from "../assets/scss/header.module.scss";
import { useNavigate } from "react-router-dom";
import { handleError, move } from "../common/utils";
import { Context } from "../App";
import {
  Box,
  Button,
  SwipeableDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Badge,
  Stack,
  IconButton,
} from "@mui/material";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import RecommendIcon from "@mui/icons-material/Recommend";
import LogoutIcon from "@mui/icons-material/Logout";
import EmailIcon from "@mui/icons-material/Email";
import { signOut } from "aws-amplify/auth";
import commonScss from "../assets/scss/common.module.scss";
import HelpIcon from "@mui/icons-material/Help";

const Header: React.FC = () => {
  const navigate = useNavigate();
  const { user, messages } = useContext(Context);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  useEffect(() => {
    if (messages) {
      setUnreadMessageCount(
        messages.filter((message) => !message.isRead).length
      );
    }
  }, [messages]);

  return (
    <header>
      <div className={headerScss.logoArea}>
        <img
          src={logo}
          className={headerScss.logo}
          alt=""
          onClick={() => {
            move(navigate, "/");
          }}
        />
        <div className={headerScss.helpGuide}>
          <IconButton
            href={process.env.REACT_APP_HELP_GUIDE_URL!}
            target="_blank"
          >
            <HelpIcon />
          </IconButton>
        </div>
        <div className={headerScss.userArea}>
          {user && (
            <>
              <Stack direction="column" alignItems="center" gap={2}>
                サインイン中: {user.username}さん
                <Stack direction="row" alignItems="center" gap={2}>
                  <Button
                    onClick={() => {
                      setOpenDrawer(true);
                    }}
                  >
                    MENU
                  </Button>
                  <Badge
                    color="error"
                    badgeContent={
                      unreadMessageCount > 0 ? unreadMessageCount : undefined
                    }
                    onClick={() => {
                      move(navigate, "/messages");
                    }}
                    className={commonScss.pointer}
                  >
                    <EmailIcon />
                  </Badge>
                </Stack>
              </Stack>
              <SwipeableDrawer
                anchor="top"
                open={openDrawer}
                onOpen={() => {}}
                onClose={() => {
                  setOpenDrawer(false);
                }}
              >
                <Box
                  onClick={() => {
                    setOpenDrawer(false);
                  }}
                >
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          move(navigate, "/");
                        }}
                      >
                        <ListItemIcon>
                          <MenuBookIcon />
                        </ListItemIcon>
                        <ListItemText>本棚</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          move(navigate, "/recommended");
                        }}
                      >
                        <ListItemIcon>
                          <RecommendIcon />
                        </ListItemIcon>
                        <ListItemText>おすすめ</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          move(navigate, "/search");
                        }}
                      >
                        <ListItemIcon>
                          <SearchIcon />
                        </ListItemIcon>
                        <ListItemText>本を探す</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          move(navigate, "/account");
                        }}
                      >
                        <ListItemIcon>
                          <PersonIcon />
                        </ListItemIcon>
                        <ListItemText>アカウント設定・削除</ListItemText>
                      </ListItemButton>
                    </ListItem>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => {
                          signOut()
                            .then(() => {
                              move(navigate, "/");
                              window.location.reload();
                            })
                            .catch((error) => {
                              handleError(error);
                            });
                        }}
                      >
                        <ListItemIcon>
                          <LogoutIcon />
                        </ListItemIcon>
                        <ListItemText>サインアウト</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </List>
                </Box>
              </SwipeableDrawer>
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
