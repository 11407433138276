import { Accordion, AccordionSummary, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MailSettings from "../components/userSettings/MailSettings";
import Details from "../components/userSettings/Details";
import GenreIds from "../components/userSettings/GenreIds";
import DeleteAccount from "../components/userSettings/DeleteAccount";
import Password from "../components/userSettings/Password";
import PersonalizationSetting from "../components/userSettings/PersonalizationSetting";
import { Context } from "../App";

const Account: React.FC = () => {
  const { userAttributes, loadUserAttributes } = useContext(Context);
  const [areUserAttributesLoaded, setAreUserAttributesLoaded] = useState(false);

  useEffect(() => {
    if (!areUserAttributesLoaded) {
      loadUserAttributes();
      setAreUserAttributesLoaded(true);
    }
  }, [loadUserAttributes, areUserAttributesLoaded]);

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>メール配信設定</Typography>
        </AccordionSummary>
        <Details
          userAttributes={userAttributes}
          isSet={areUserAttributesLoaded}
          content={
            <MailSettings
              userAttributes={userAttributes}
              isSet={areUserAttributesLoaded}
            />
          }
        />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>データの取扱設定</Typography>
        </AccordionSummary>
        <Details
          userAttributes={userAttributes}
          isSet={areUserAttributesLoaded}
          content={<PersonalizationSetting />}
        />
      </Accordion>
      {/* TODO UI改善策が出るまで非表示にする */}
      {/* <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>おすすめ可能ジャンル設定</Typography>
        </AccordionSummary>
        <Details
          userAttributes={userAttributes}
          isSet={areUserAttributesLoaded}
          content={<GenreIds userAttributes={userAttributes} isSet={areUserAttributesLoaded} />}
        />
      </Accordion> */}
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>パスワード変更</Typography>
        </AccordionSummary>
        <Details
          userAttributes={userAttributes}
          isSet={areUserAttributesLoaded}
          content={<Password />}
        />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>アカウント削除</Typography>
        </AccordionSummary>
        <Details
          userAttributes={userAttributes}
          isSet={areUserAttributesLoaded}
          content={<DeleteAccount />}
        />
      </Accordion>
    </>
  );
};

export default Account;
