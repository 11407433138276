import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import getAxiosInstance from "../common/Axios";
import { AxiosError } from "axios";
import commonScss from "../assets/scss/common.module.scss";
import { handleError } from "../common/utils";

const PersonalizationSettingDialog: React.FC = () => {
  const [isSet, setIsSet] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [canRecommendOneself, setCanRecommendOneself] = useState(false);
  const [canRecommendOthers, setCanRecommendOthers] = useState(false);
  const { addLoadingProcess, deleteLoadingProcess, userAttributes } =
    useContext(Context);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        const axiosInstance = await getAxiosInstance();
        axiosInstance
          .get("/personalizationSetting")
          .then((response) => {
            setIsSet(!!response.data);
          })
          .catch((error) => {
            const notFound =
              error instanceof AxiosError &&
              ((error.response && error.response.status === 404) ||
                error.status === 404);
            if (notFound) {
              setIsSet(false);
            } else {
              console.log(error);
              setIsSet(true);
            }
          })
          .finally(() => {
            setIsLoaded(true);
          });
      })();
    }
  }, [isLoaded]);

  useEffect(() => {
    setOpen(
      !isSet &&
        !!userAttributes &&
        userAttributes["custom:acknowledgement"] ===
          process.env.REACT_APP_ACKNOWLEDGEMENT
    );
  }, [isSet, userAttributes]);

  return (
    <Dialog open={open} fullWidth>
      <DialogTitle className={commonScss.textCenter}>
        データの取扱設定
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" alignItems="center" gap={2}>
          本サービスではおすすめ情報の更新などのため、登録された本の情報などを活用しております。
          <FormControlLabel
            control={
              <Switch
                checked={canRecommendOneself}
                onChange={(_, checked) => {
                  setCanRecommendOneself(checked);
                }}
              />
            }
            label="登録した本などの情報を元に、自分に対するおすすめ情報が作成・更新されることに同意します"
          />
          <FormControlLabel
            control={
              <Switch
                checked={canRecommendOthers}
                onChange={(_, checked) => {
                  setCanRecommendOthers(checked);
                }}
              />
            }
            label="登録した本などの情報を元に、他アカウントに対するおすすめ情報が作成・更新されることに同意します"
          />
          <span>
            ※本設定は MENU &gt; アカウント設定・削除にていつでも変更できます。
            <br />
            ※変更前に始まった処理には反映されない場合がございます。
          </span>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={async () => {
            const processId = addLoadingProcess();
            const axiosInstance = await getAxiosInstance();
            axiosInstance
              .post("/personalizationSetting", {
                canRecommendOneself,
                canRecommendOthers,
              })
              .then(() => {
                setIsSet(true);
              })
              .catch((error) => {
                handleError(error);
              })
              .finally(() => {
                deleteLoadingProcess(processId);
              });
          }}
        >
          設定して閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonalizationSettingDialog;
