import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import { PersonalizationSetting as PersonalizationSettingModel } from "../../common/model";
import getAxiosInstance from "../../common/Axios";
import { AxiosError } from "axios";
import { handleError } from "../../common/utils";
import { Switch, FormControlLabel } from "@mui/material";

const PersonalizationSetting: React.FC = () => {
  const [canRecommendOneself, setCanRecommendOneself] = useState(false);
  const [canRecommendOthers, setCanRecommendOthers] = useState(false);
  const { addLoadingProcess, deleteLoadingProcess } = useContext(Context);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (!isLoaded) {
      (async () => {
        const processId = addLoadingProcess();
        const axiosInstance = await getAxiosInstance();
        axiosInstance
          .get("/personalizationSetting")
          .then((response) => {
            const personalizationSetting =
              response.data as PersonalizationSettingModel;
            setCanRecommendOneself(personalizationSetting.canRecommendOneself);
            setCanRecommendOthers(personalizationSetting.canRecommendOthers);
            setIsLoaded(true);
          })
          .catch((error) => {
            if (
              error instanceof AxiosError &&
              ((error.response && error.response.status === 404) ||
                error.status === 404)
            ) {
              console.log(error);
              setIsLoaded(true);
            } else {
              handleError(error);
            }
          })
          .finally(() => {
            deleteLoadingProcess(processId);
          });
      })();
    }
  }, [isLoaded, addLoadingProcess, deleteLoadingProcess]);

  const update = async (data: {
    canRecommendOneself: boolean;
    canRecommendOthers: boolean;
  }): Promise<void> => {
    const processId = addLoadingProcess();
    const axiosInstance = await getAxiosInstance();
    axiosInstance
      .post("/personalizationSetting", data)
      .then(() => {
        setCanRecommendOneself(data.canRecommendOneself);
        setCanRecommendOthers(data.canRecommendOthers);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        deleteLoadingProcess(processId);
      });
  };

  return (
    <>
      <FormControlLabel
        control={
          <Switch
            checked={canRecommendOneself}
            onChange={(_, checked) => {
              update({
                canRecommendOneself: checked,
                canRecommendOthers,
              });
            }}
          />
        }
        label="登録した本などの情報を元に、自分に対するおすすめ情報が作成・更新されることに同意します"
      />
      <FormControlLabel
        control={
          <Switch
            checked={canRecommendOthers}
            onChange={(_, checked) => {
              update({
                canRecommendOneself,
                canRecommendOthers: checked,
              });
            }}
          />
        }
        label="登録した本などの情報を元に、他アカウントに対するおすすめ情報が作成・更新されることに同意します"
      />
      <br />
      ※変更前に始まった処理には反映されない場合がございます。
    </>
  );
};

export default PersonalizationSetting;
