import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CommonProps from "./CommonProps";
import { updateUserAttribute } from "aws-amplify/auth";
import { Context } from "../../App";
import { handleError } from "../../common/utils";

const MailSettings: React.FC<CommonProps> = (props) => {
  const { userAttributes, isSet } = props;
  const { addLoadingProcess, deleteLoadingProcess, loadUserAttributes } =
    useContext(Context);
  const [receiveRecommendMail, setReceiveRecommendMail] = useState(true);
  const [receiveInfoMail, setReceiveInfoMail] = useState(true);

  useEffect(() => {
    if (userAttributes && isSet) {
      setReceiveRecommendMail(
        userAttributes["custom:receiveRecommendMail"] === undefined ||
          userAttributes["custom:receiveRecommendMail"] === "true"
      );
      setReceiveInfoMail(
        userAttributes["custom:receiveInfoMail"] === undefined ||
          userAttributes["custom:receiveInfoMail"] === "true"
      );
    }
  }, [userAttributes, isSet]);

  return (
    <>
      配信を行うメールの種類が設定できます
      <FormGroup>
        <FormControlLabel
          control={<Switch disabled defaultChecked />}
          label="重要メール"
        />
        <FormControlLabel
          control={
            <Switch
              checked={receiveRecommendMail}
              onChange={async (_, checked) => {
                const processId = addLoadingProcess();
                updateUserAttribute({
                  userAttribute: {
                    attributeKey: "custom:receiveRecommendMail",
                    value: checked ? "true" : "false",
                  },
                })
                  .catch((error) => {
                    handleError(error);
                  })
                  .finally(() => {
                    deleteLoadingProcess(processId);
                    loadUserAttributes();
                  });
              }}
            />
          }
          label="おすすめ通知"
        />
        <FormControlLabel
          control={
            <Switch
              checked={receiveInfoMail}
              onChange={async (_, checked) => {
                const processId = addLoadingProcess();
                updateUserAttribute({
                  userAttribute: {
                    attributeKey: "custom:receiveInfoMail",
                    value: checked ? "true" : "false",
                  },
                })
                  .catch((error) => {
                    handleError(error);
                  })
                  .finally(() => {
                    deleteLoadingProcess(processId);
                    loadUserAttributes();
                  });
              }}
            />
          }
          label="その他お知らせ通知"
        />
      </FormGroup>
      <br />
      ※メールアドレスの変更はできません。必要に応じて、アカウントを削除したのち別メールアドレスにて新規アカウント作成を行なってください。
    </>
  );
};

export default MailSettings;
