import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";

interface Props {
  isLoading: boolean;
}

const Loading: React.FC<Props> = (props) => {
  const { isLoading } = props;

  return (
    <Backdrop
      open={isLoading}
      sx={{ color: "#fff", zIndex: Number.MAX_SAFE_INTEGER }}
    >
      <CircularProgress />
    </Backdrop>
  );
};

export default Loading;
