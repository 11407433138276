import React from "react";
import { ApiBook } from "../common/model";
import { ImageList } from "@mui/material";
import BookItem from "./BookItem";

interface Props {
  books: ApiBook[];
}

const ApiBooks: React.FC<Props> = (props) => {
  const { books } = props;

  return (
    <ImageList cols={4}>
      {books.map((book) => {
        return (
          <BookItem
            key={book.isbn || book.jan}
            book={{
              isbnjan: book.isbn || book.jan,
              imageUrl:
                book.largeImageUrl || book.mediumImageUrl || book.smallImageUrl,
              title: book.title,
              author: book.author,
              publisherName: book.publisherName,
              itemCaption: book.itemCaption,
              itemUrl: book.affiliateUrl || book.itemUrl,
              booksGenreId: book.booksGenreId.split("/"),
            }}
          />
        );
      })}
    </ImageList>
  );
};

export default ApiBooks;
