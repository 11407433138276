import React, { useContext } from "react";
import { ImageListItem } from "@mui/material";
import { BookDisplayInfo, OpenBookDialogEvent } from "../common/model";
import { Context } from "../App";
import bookItemScss from "../assets/scss/bookItem.module.scss";
import { awsRum } from "..";

interface Props {
  book: BookDisplayInfo;
  openBookDialogEvent?: OpenBookDialogEvent;
}

const BookItem: React.FC<Props> = (props) => {
  const { book, openBookDialogEvent } = props;
  const { setBookDisplayInfo } = useContext(Context);

  return (
    <ImageListItem
      className={bookItemScss.item}
      onClick={() => {
        setBookDisplayInfo(book);
        if (awsRum && openBookDialogEvent) {
          try {
            awsRum.recordEvent("openBookDialog", openBookDialogEvent);
          } catch (error) {
            console.log(error);
          }
        }
      }}
    >
      <img src={book.imageUrl} alt="" />
    </ImageListItem>
  );
};

export default BookItem;
