import { Button, Stack, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../App";
import { confirmSignUp, resendSignUpCode, autoSignIn } from "aws-amplify/auth";
import { handleError } from "../../common/utils";

interface Props {
  signinUsername: string;
}

const ConfirmSignUp: React.FC<Props> = (props) => {
  const { signinUsername } = props;
  const { addLoadingProcess, deleteLoadingProcess, setIsConfirmingSignUp } =
    useContext(Context);
  const [confirmationCode, setConfirmationCode] = useState("");

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <TextField
        value={confirmationCode}
        label="確認コード"
        fullWidth
        required
        variant="standard"
        onChange={(e) => {
          setConfirmationCode(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          const processId = addLoadingProcess();
          confirmSignUp({ username: signinUsername, confirmationCode })
            .then(({ isSignUpComplete, nextStep }) => {
              if (isSignUpComplete) {
                setIsConfirmingSignUp(false);
                setConfirmationCode("");
                const process2Id = addLoadingProcess();
                autoSignIn()
                  .catch((error) => {
                    handleError(error);
                  })
                  .finally(() => {
                    deleteLoadingProcess(process2Id);
                  });
              }
              console.log(nextStep);
            })
            .catch((error) => {
              handleError(error);
            })
            .finally(() => {
              deleteLoadingProcess(processId);
            });
        }}
        disabled={!confirmationCode}
        variant="contained"
      >
        送信
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          const processId = addLoadingProcess();
          resendSignUpCode({ username: signinUsername })
            .then(() => {
              alert("確認コードが再送信されました");
            })
            .catch((error) => {
              handleError(error);
            })
            .finally(() => {
              deleteLoadingProcess(processId);
            });
        }}
      >
        確認コードを再送信
      </Button>
    </Stack>
  );
};

export default ConfirmSignUp;
