import React from "react";
import { useZxing } from "react-zxing";
import AbcIcon from "@mui/icons-material/Abc";
import barcodeScannerScss from "../assets/scss/barcodeScanner.module.scss";
import { Button } from "@mui/material";

interface Props {
  setIsScanning: (value: boolean) => void;
  setKeyword: (value: string) => void;
}

const BarCodeScanner: React.FC<Props> = (props) => {
  const { setIsScanning, setKeyword } = props;

  const { ref } = useZxing({
    onDecodeResult: (result) => {
      const text = result.getText();
      if (/\d{13}/.test(text)) {
        setKeyword(text);
        setIsScanning(false);
      } else {
        alert(
          "これはISBNコードでないか、上手く読み取れていません\nISBNコードは13桁の数字です"
        );
      }
    },
  });

  return (
    <div>
      <video ref={ref} className={barcodeScannerScss.video} />
      <div className={barcodeScannerScss.rectangle} />
      <Button
        variant="contained"
        onClick={() => {
          setIsScanning(false);
        }}
        startIcon={<AbcIcon />}
        className={barcodeScannerScss.close}
      >
        キーワード検索
      </Button>
    </div>
  );
};

export default BarCodeScanner;
