import { Grid } from "@mui/material";
import React from "react";

interface Props {
  children: React.ReactNode;
}

const CommonGrid: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <Grid
      container
      columns={{
        xs: 1,
        md: 5,
        xl: 9,
      }}
    >
      <Grid xs={0} md={1} xl={1} />
      <Grid xs={1} md={3} xl={7}>
        {children}
      </Grid>
      <Grid xs={0} md={1} xl={1} />
    </Grid>
  );
};

export default CommonGrid;
