import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import {
  Pagination,
  Stack,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import MessageRow from "../components/messages/MessageRow";
import MessageDialog from "../components/messages/MessageDialog";

const Messages: React.FC = () => {
  const { messages, loadMessages } = useContext(Context);
  const [isLoaded, setIsLoaded] = useState(false);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);
  const [index, setIndex] = useState<number | undefined>();

  useEffect(() => {
    if (!isLoaded) {
      loadMessages().finally(() => {
        setIsLoaded(true);
      });
    }
  }, [isLoaded, loadMessages]);

  useEffect(() => {
    setCount(Math.max(Math.ceil(messages.length / 30), 1));
  }, [messages]);

  const [listedMessages, setListedMessages] = useState(messages);
  useEffect(() => {
    setListedMessages(messages.slice(30 * (page - 1), 30 * page));
  }, [messages, page]);

  return (
    <>
      <Stack alignItems="center">
        <Pagination
          count={count}
          page={page}
          onChange={(_, newPage) => {
            setPage(newPage);
          }}
        />
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>件名</TableCell>
                <TableCell>送信日時</TableCell>
              </TableRow>
            </TableHead>
            <TableHead>
              {listedMessages.map((message, index) => {
                return (
                  <MessageRow
                    message={message}
                    key={message.dataId}
                    index={index}
                    setIndex={setIndex}
                  />
                );
              })}
            </TableHead>
          </Table>
        </TableContainer>
      </Stack>
      <MessageDialog messages={messages} index={index} setIndex={setIndex} />
    </>
  );
};

export default Messages;
