import { Button, MobileStepper, Stack, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import intro01 from "../assets/images/introduction/intro01.png";
import intro02 from "../assets/images/introduction/intro02.png";
import intro03 from "../assets/images/introduction/intro03.png";
import intro04 from "../assets/images/introduction/intro04.png";
import CommonGrid from "./CommonGrid";
import commonScss from "../assets/scss/common.module.scss";
import introductionScss from "../assets/scss/introduction.module.scss";
import { Context } from "../App";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Introduction: React.FC = () => {
  const { user } = useContext(Context);
  const [activeStep, setActiveStep] = useState(0);
  const steps = [
    {
      image: intro01,
      label: "読書はお好きでしょうか?",
    },
    {
      image: intro02,
      label: "読んだ本や気になる本を Another Book に記録しましょう",
    },
    {
      image: intro03,
      label:
        "5点以上読んだ本を登録すると、読書傾向があなたと違うアカウントに愛読される本をおすすめします",
    },
    {
      image: intro04,
      label:
        "普段読まない本に手を伸ばして、フィルターバブル解消を目指しましょう",
    },
  ] as const;

  return (
    <>
      {!user && (
        <CommonGrid>
          <Stack direction="column" gap={2} alignItems="center">
            <br />
            <Typography variant="h6">Another Book のご紹介</Typography>
            <span className={introductionScss.label}>
              {activeStep + 1}. {steps[activeStep].label}
            </span>

            <AutoPlaySwipeableViews
              axis="x"
              index={activeStep}
              onChangeIndex={(step) => {
                setActiveStep(step);
              }}
              enableMouseEvents
            >
              {steps.map((step, index) => (
                <div key={step.image} style={{ width: "100%" }}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <img
                      src={step.image}
                      alt=""
                      className={`${commonScss.fullWidth} ${commonScss.textCenter}`}
                    />
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              steps={steps.length}
              activeStep={activeStep}
              nextButton={
                <Button
                  onClick={() => {
                    setActiveStep(activeStep + 1);
                  }}
                  disabled={activeStep === steps.length - 1}
                >
                  次へ
                </Button>
              }
              backButton={
                <Button
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                  disabled={activeStep === 0}
                >
                  前へ
                </Button>
              }
              position="static"
            />
          </Stack>
        </CommonGrid>
      )}
    </>
  );
};

export default Introduction;
