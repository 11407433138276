import React, { createContext, useCallback, useEffect, useState } from "react";
import { Amplify } from "aws-amplify";
import { I18n } from "aws-amplify/utils";
import { translations } from "@aws-amplify/ui-react";
import * as uuid from "uuid";
import Content from "./Content";
import {
  AuthUser,
  FetchUserAttributesOutput,
  fetchUserAttributes,
  getCurrentUser,
} from "aws-amplify/auth";
import ScrollBar from "./components/ScrollBar";
import {
  BookDisplayInfo,
  Message,
  Recommendation,
  SavedBook,
} from "./common/model";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Loading from "./components/Loading";
import Footer from "./components/Footer";
import { BrowserRouter } from "react-router-dom";
import getAxiosInstance from "./common/Axios";
import { BookIndexName } from "./common/const";
import { handleError } from "./common/utils";

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID!,
    },
  },
});

I18n.putVocabularies(translations);
I18n.setLanguage("ja");

interface ContextProps {
  addLoadingProcess: () => string;
  deleteLoadingProcess: (processId: string) => void;
  user: AuthUser | undefined;
  userAttributes: FetchUserAttributesOutput | undefined;
  loadUserAttributes: () => void;
  bookDisplayInfo: BookDisplayInfo | null;
  setBookDisplayInfo: (value: BookDisplayInfo | null) => void;
  books: SavedBook[];
  setBookIndexName: (value: BookIndexName) => void;
  loadBooks: () => Promise<void>;
  recommendation: Recommendation | undefined;
  isNewRecommendation: boolean;
  loadRecommendation: () => Promise<void>;
  messages: Message[];
  loadMessages: () => Promise<void>;
  isConfirmingSignUp: boolean;
  setIsConfirmingSignUp: (value: boolean) => void;
  isResettingPassword: boolean;
  setIsResettingPassword: (value: boolean) => void;
}

export const Context: React.Context<ContextProps> = createContext<ContextProps>(
  {
    addLoadingProcess: () => {
      return "";
    },
    deleteLoadingProcess: (processId: string) => {},
    user: undefined,
    userAttributes: undefined,
    loadUserAttributes: () => {},
    bookDisplayInfo: null,
    setBookDisplayInfo: (value: BookDisplayInfo | null) => {},
    books: [],
    setBookIndexName: (value: BookIndexName) => {},
    loadBooks: async () => {},
    recommendation: undefined,
    isNewRecommendation: false,
    loadRecommendation: async () => {},
    messages: [],
    loadMessages: async () => {},
    isConfirmingSignUp: false,
    setIsConfirmingSignUp: (value: boolean) => {},
    isResettingPassword: false,
    setIsResettingPassword: (value: boolean) => {},
  }
);

const App: React.FC = () => {
  const [loadingProcesses, setLoadingProcesses] = useState(new Set<string>());
  const addLoadingProcess = useCallback((): string => {
    let processId = uuid.v4();
    while (loadingProcesses.has(processId)) {
      processId = uuid.v4();
    }
    loadingProcesses.add(processId);
    setLoadingProcesses(loadingProcesses);
    return processId;
  }, [loadingProcesses]);
  const deleteLoadingProcess = useCallback(
    (processId: string): void => {
      loadingProcesses.delete(processId);
      setLoadingProcesses(loadingProcesses);
    },
    [loadingProcesses]
  );

  const [triggerUser, setTriggerUser] = useState(true);
  const [user, setUser] = useState<AuthUser | undefined>();

  useEffect(() => {
    (async () => {
      await getCurrentUser()
        .then(async (currentUser) => {
          setUser(currentUser);
        })
        .catch(() => {
          setUser(undefined);
        })
        .finally(() => {
          setTimeout(() => {
            setTriggerUser(!triggerUser);
          }, 1000);
        });
    })();
  }, [triggerUser]);

  const [areUserAttributesLoaded, setAreUserAttributesLoaded] = useState(false);
  const [userAttributes, setUserAttributes] = useState<
    FetchUserAttributesOutput | undefined
  >();
  const loadUserAttributes = useCallback((): void => {
    const processId = addLoadingProcess();
    fetchUserAttributes()
      .then((result) => {
        setUserAttributes(result);
      })
      .catch((error) => {
        handleError(error);
        setUser(undefined);
      })
      .finally(() => {
        deleteLoadingProcess(processId);
      });
  }, [addLoadingProcess, deleteLoadingProcess]);
  useEffect(() => {
    if (!areUserAttributesLoaded && !!user) {
      loadUserAttributes();
      setAreUserAttributesLoaded(true);
    }
  }, [loadUserAttributes, areUserAttributesLoaded, user]);

  const [bookDisplayInfo, setBookDisplayInfo] =
    useState<BookDisplayInfo | null>(null);

  const [books, setBooks] = useState<SavedBook[]>([]);
  const [bookIndexName, setBookIndexName] = useState(BookIndexName.author);
  const [areBooksLoaded, setAreBooksLoaded] = useState(false);
  const loadBooks = useCallback(async (): Promise<void> => {
    const processId = addLoadingProcess();
    const axiosIntance = await getAxiosInstance();
    axiosIntance
      .get("/books", {
        params: {
          indexName: bookIndexName,
        },
      })
      .then((response) => {
        setBooks(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        deleteLoadingProcess(processId);
      });
  }, [bookIndexName, addLoadingProcess, deleteLoadingProcess]);
  useEffect(() => {
    if (user && !areBooksLoaded) {
      loadBooks().then(() => {
        setAreBooksLoaded(true);
      });
    }
  }, [user, areBooksLoaded, loadBooks]);

  const [recommendation, setRecommendation] = useState<
    Recommendation | undefined
  >();
  const [isRecommendationLoaded, setIsRecommendationLoaded] = useState(false);
  const loadRecommendation = useCallback(async (): Promise<void> => {
    const processId = addLoadingProcess();
    const axiosInstance = await getAxiosInstance();
    axiosInstance
      .get("/recommended")
      .then((response) => {
        setRecommendation(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        deleteLoadingProcess(processId);
      });
  }, [addLoadingProcess, deleteLoadingProcess]);
  useEffect(() => {
    if (user && !isRecommendationLoaded) {
      loadRecommendation().then(() => {
        setIsRecommendationLoaded(true);
      });
    }
  }, [user, isRecommendationLoaded, loadRecommendation]);

  const [isNewRecommendation, setIsNewRecommendation] = useState(false);
  useEffect(() => {
    let result = false;
    if (
      recommendation &&
      recommendation.recommendationProcessId &&
      recommendation.list.length > 0 &&
      userAttributes &&
      recommendation.recommendationProcessId !==
        userAttributes["custom:lastViewedRPId"]
    ) {
      result = true;
    }
    setIsNewRecommendation(result);
  }, [recommendation, userAttributes]);

  const [areMessagesLoaded, setAreMessagesLoaded] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const loadMessages = async (): Promise<void> => {
    const processId = addLoadingProcess();
    const axiosInstance = await getAxiosInstance();
    axiosInstance
      .get("/messages")
      .then((response) => {
        setMessages(response.data);
      })
      .catch((error) => {
        handleError(error);
      })
      .finally(() => {
        deleteLoadingProcess(processId);
      });
  };
  useEffect(() => {
    if (user && !areMessagesLoaded) {
      (async () => {
        const axiosInstance = await getAxiosInstance();
        axiosInstance
          .get("/messages")
          .then((response) => {
            setMessages(response.data);
            setAreMessagesLoaded(true);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
  }, [areMessagesLoaded, user]);

  const [isConfirmingSignUp, setIsConfirmingSignUp] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);

  return (
    <BrowserRouter>
      <Context.Provider
        value={{
          addLoadingProcess,
          deleteLoadingProcess,
          user,
          userAttributes,
          loadUserAttributes,
          bookDisplayInfo,
          setBookDisplayInfo,
          books,
          setBookIndexName,
          loadBooks,
          recommendation,
          isNewRecommendation,
          loadRecommendation,
          messages,
          loadMessages,
          isConfirmingSignUp,
          setIsConfirmingSignUp,
          isResettingPassword,
          setIsResettingPassword,
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ja">
          <Content />
        </LocalizationProvider>
        <Footer />
        <ScrollBar />
        <Loading isLoading={loadingProcesses.size > 0} />
      </Context.Provider>
    </BrowserRouter>
  );
};

export default App;
