export const enum BookIndexName {
  bookCreatedAt = "bookCreatedAt",
  bookReadAt = "bookReadAt",
  title = "title",
  author = "author",
}

export const Genres = [
  {
    id: "001",
    name: "本",
    children: [
      {
        id: "001001",
        name: "漫画（コミック）",
        children: [
          {
            id: "001001001",
            name: "少年",
            children: [],
          },
          {
            id: "001001002",
            name: "少女",
            children: [],
          },
          {
            id: "001001003",
            name: "青年",
            children: [],
          },
          {
            id: "001001004",
            name: "レディース",
            children: [],
          },
          {
            id: "001001006",
            name: "文庫",
            children: [],
          },
          {
            id: "001001012",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001002",
        name: "語学・学習参考書",
        children: [
          {
            id: "001002001",
            name: "語学学習",
            children: [],
          },
          {
            id: "001002002",
            name: "語学辞書",
            children: [],
          },
          {
            id: "001002003",
            name: "辞典",
            children: [],
          },
          {
            id: "001002005",
            name: "語学関係資格",
            children: [],
          },
          {
            id: "001002006",
            name: "学習参考書・問題集",
            children: [],
          },
          {
            id: "001002008",
            name: "図鑑・資料集",
            children: [],
          },
          {
            id: "001002007",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001003",
        name: "絵本・児童書・図鑑",
        children: [
          {
            id: "001003001",
            name: "児童書",
            children: [],
          },
          {
            id: "001003002",
            name: "児童文庫",
            children: [],
          },
          {
            id: "001003003",
            name: "絵本",
            children: [],
          },
          {
            id: "001003004",
            name: "民話・むかし話",
            children: [],
          },
          {
            id: "001003005",
            name: "しかけ絵本",
            children: [],
          },
          {
            id: "001003006",
            name: "図鑑・ちしき",
            children: [],
          },
          {
            id: "001003007",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001004",
        name: "小説・エッセイ",
        children: [
          {
            id: "001004001",
            name: "ミステリー・サスペンス",
            children: [],
          },
          {
            id: "001004002",
            name: "SF・ホラー",
            children: [],
          },
          {
            id: "001004003",
            name: "エッセイ",
            children: [],
          },
          {
            id: "001004004",
            name: "ノンフィクション",
            children: [],
          },
          {
            id: "001004008",
            name: "日本の小説",
            children: [],
          },
          {
            id: "001004009",
            name: "外国の小説",
            children: [],
          },
          {
            id: "001004016",
            name: "ロマンス",
            children: [],
          },
          {
            id: "001004015",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001005",
        name: "パソコン・システム開発",
        children: [
          {
            id: "001005001",
            name: "ハードウェア",
            children: [],
          },
          {
            id: "001005002",
            name: "入門書",
            children: [],
          },
          {
            id: "001005003",
            name: "インターネット・WEBデザイン",
            children: [],
          },
          {
            id: "001005004",
            name: "ネットワーク",
            children: [],
          },
          {
            id: "001005005",
            name: "プログラミング",
            children: [],
          },
          {
            id: "001005006",
            name: "アプリケーション",
            children: [],
          },
          {
            id: "001005007",
            name: "OS",
            children: [],
          },
          {
            id: "001005008",
            name: "デザイン・グラフィックス",
            children: [],
          },
          {
            id: "001005009",
            name: "ITパスポート",
            children: [],
          },
          {
            id: "001005010",
            name: "MOUS・MOT",
            children: [],
          },
          {
            id: "001005011",
            name: "パソコン検定",
            children: [],
          },
          {
            id: "001005013",
            name: "IT・eコマース",
            children: [],
          },
          {
            id: "001005017",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001006",
        name: "ビジネス・経済・就職",
        children: [
          {
            id: "001006001",
            name: "経済・財政",
            children: [],
          },
          {
            id: "001006002",
            name: "流通",
            children: [],
          },
          {
            id: "001006003",
            name: "IT・eコマース",
            children: [],
          },
          {
            id: "001006004",
            name: "マーケティング・セールス",
            children: [],
          },
          {
            id: "001006005",
            name: "投資・株・資産運用",
            children: [],
          },
          {
            id: "001006006",
            name: "マネープラン",
            children: [],
          },
          {
            id: "001006007",
            name: "マネジメント・人材管理",
            children: [],
          },
          {
            id: "001006008",
            name: "経理",
            children: [],
          },
          {
            id: "001006009",
            name: "自己啓発",
            children: [],
          },
          {
            id: "001006010",
            name: "就職・転職",
            children: [],
          },
          {
            id: "001006011",
            name: "MBA",
            children: [],
          },
          {
            id: "001006013",
            name: "証券アナリスト",
            children: [],
          },
          {
            id: "001006014",
            name: "税理士・公認会計士・ファイナンシャルプランナー",
            children: [],
          },
          {
            id: "001006015",
            name: "簿記検定",
            children: [],
          },
          {
            id: "001006016",
            name: "銀行・金融検定",
            children: [],
          },
          {
            id: "001006018",
            name: "経営",
            children: [],
          },
          {
            id: "001006019",
            name: "産業",
            children: [],
          },
          {
            id: "001006022",
            name: "アフィリエイト",
            children: [],
          },
          {
            id: "001006023",
            name: "ビジネスマナー",
            children: [],
          },
          {
            id: "001006024",
            name: "金融",
            children: [],
          },
          {
            id: "001006021",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001007",
        name: "旅行・留学・アウトドア",
        children: [
          {
            id: "001007001",
            name: "旅行",
            children: [],
          },
          {
            id: "001007003",
            name: "温泉",
            children: [],
          },
          {
            id: "001007004",
            name: "鉄道の旅",
            children: [],
          },
          {
            id: "001007005",
            name: "テーマパーク",
            children: [],
          },
          {
            id: "001007006",
            name: "ガイドブック",
            children: [],
          },
          {
            id: "001007007",
            name: "地図",
            children: [],
          },
          {
            id: "001007008",
            name: "留学・海外赴任",
            children: [],
          },
          {
            id: "001007010",
            name: "旅行主任者",
            children: [],
          },
          {
            id: "001007011",
            name: "紀行・旅行エッセイ",
            children: [],
          },
          {
            id: "001007012",
            name: "釣り",
            children: [],
          },
          {
            id: "001007014",
            name: "キャンプ",
            children: [],
          },
          {
            id: "001007013",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001008",
        name: "人文・思想・社会",
        children: [
          {
            id: "001008001",
            name: "雑学・出版・ジャーナリズム",
            children: [],
          },
          {
            id: "001008002",
            name: "哲学・思想",
            children: [],
          },
          {
            id: "001008003",
            name: "心理学",
            children: [],
          },
          {
            id: "001008004",
            name: "宗教・倫理",
            children: [],
          },
          {
            id: "001008005",
            name: "歴史",
            children: [],
          },
          {
            id: "001008006",
            name: "地理",
            children: [],
          },
          {
            id: "001008008",
            name: "社会科学",
            children: [],
          },
          {
            id: "001008009",
            name: "法律",
            children: [],
          },
          {
            id: "001008010",
            name: "政治",
            children: [],
          },
          {
            id: "001008011",
            name: "社会",
            children: [],
          },
          {
            id: "001008012",
            name: "教育・福祉",
            children: [],
          },
          {
            id: "001008015",
            name: "民俗",
            children: [],
          },
          {
            id: "001008016",
            name: "軍事",
            children: [],
          },
          {
            id: "001008017",
            name: "ノンフィクション",
            children: [],
          },
          {
            id: "001008018",
            name: "言語学",
            children: [],
          },
          {
            id: "001008022",
            name: "文学",
            children: [],
          },
          {
            id: "001008027",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001009",
        name: "ホビー・スポーツ・美術",
        children: [
          {
            id: "001009001",
            name: "スポーツ",
            children: [],
          },
          {
            id: "001009002",
            name: "格闘技",
            children: [],
          },
          {
            id: "001009003",
            name: "車・バイク",
            children: [],
          },
          {
            id: "001009004",
            name: "鉄道",
            children: [],
          },
          {
            id: "001009005",
            name: "登山・アウトドア・釣り",
            children: [],
          },
          {
            id: "001009006",
            name: "カメラ・写真",
            children: [],
          },
          {
            id: "001009007",
            name: "囲碁・将棋・クイズ",
            children: [],
          },
          {
            id: "001009008",
            name: "ギャンブル",
            children: [],
          },
          {
            id: "001009009",
            name: "美術",
            children: [],
          },
          {
            id: "001009010",
            name: "工芸・工作",
            children: [],
          },
          {
            id: "001009011",
            name: "茶道・香道・華道",
            children: [],
          },
          {
            id: "001009012",
            name: "ミリタリー",
            children: [],
          },
          {
            id: "001009013",
            name: "トレーディングカード",
            children: [],
          },
          {
            id: "001009015",
            name: "自転車",
            children: [],
          },
          {
            id: "001009014",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001010",
        name: "美容・暮らし・健康・料理",
        children: [
          {
            id: "001010001",
            name: "恋愛",
            children: [],
          },
          {
            id: "001010002",
            name: "妊娠・出産・子育て",
            children: [],
          },
          {
            id: "001010003",
            name: "ペット",
            children: [],
          },
          {
            id: "001010004",
            name: "住まい・インテリア",
            children: [],
          },
          {
            id: "001010005",
            name: "ガーデニング・フラワー",
            children: [],
          },
          {
            id: "001010006",
            name: "生活の知識",
            children: [],
          },
          {
            id: "001010007",
            name: "占い",
            children: [],
          },
          {
            id: "001010008",
            name: "冠婚葬祭・マナー",
            children: [],
          },
          {
            id: "001010009",
            name: "手芸",
            children: [],
          },
          {
            id: "001010010",
            name: "健康",
            children: [],
          },
          {
            id: "001010011",
            name: "料理",
            children: [],
          },
          {
            id: "001010012",
            name: "ドリンク・お酒",
            children: [],
          },
          {
            id: "001010013",
            name: "生き方・リラクゼーション",
            children: [],
          },
          {
            id: "001010014",
            name: "ファッション・美容",
            children: [],
          },
          {
            id: "001010016",
            name: "雑貨",
            children: [],
          },
          {
            id: "001010015",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001011",
        name: "エンタメ・ゲーム",
        children: [
          {
            id: "001011001",
            name: "テレビ関連本",
            children: [],
          },
          {
            id: "001011002",
            name: "映画",
            children: [],
          },
          {
            id: "001011003",
            name: "音楽",
            children: [],
          },
          {
            id: "001011005",
            name: "ゲーム",
            children: [],
          },
          {
            id: "001011006",
            name: "演劇・舞踊",
            children: [],
          },
          {
            id: "001011007",
            name: "演芸",
            children: [],
          },
          {
            id: "001011009",
            name: "アニメーション",
            children: [],
          },
          {
            id: "001011010",
            name: "フィギュア",
            children: [],
          },
          {
            id: "001011011",
            name: "サブカルチャー",
            children: [],
          },
          {
            id: "001011013",
            name: "タレント関連本",
            children: [],
          },
          {
            id: "001011012",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001012",
        name: "科学・技術",
        children: [
          {
            id: "001012001",
            name: "自然科学全般",
            children: [],
          },
          {
            id: "001012002",
            name: "数学",
            children: [],
          },
          {
            id: "001012003",
            name: "物理学",
            children: [],
          },
          {
            id: "001012004",
            name: "化学",
            children: [],
          },
          {
            id: "001012005",
            name: "地学・天文学",
            children: [],
          },
          {
            id: "001012006",
            name: "生物学",
            children: [],
          },
          {
            id: "001012007",
            name: "植物学",
            children: [],
          },
          {
            id: "001012008",
            name: "動物学",
            children: [],
          },
          {
            id: "001012010",
            name: "工学",
            children: [],
          },
          {
            id: "001012011",
            name: "建築学",
            children: [],
          },
          {
            id: "001012016",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001013",
        name: "写真集・タレント",
        children: [
          {
            id: "001013001",
            name: "グラビアアイドル・タレント写真集",
            children: [],
          },
          {
            id: "001013003",
            name: "動物・自然",
            children: [],
          },
          {
            id: "001013002",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001016",
        name: "資格・検定",
        children: [
          {
            id: "001016001",
            name: "看護・医療関係資格",
            children: [],
          },
          {
            id: "001016002",
            name: "法律関係資格",
            children: [],
          },
          {
            id: "001016003",
            name: "ビジネス関係資格",
            children: [],
          },
          {
            id: "001016004",
            name: "宅建・不動産関係資格",
            children: [],
          },
          {
            id: "001016005",
            name: "食品・調理関係資格",
            children: [],
          },
          {
            id: "001016006",
            name: "教育・心理関係資格",
            children: [],
          },
          {
            id: "001016007",
            name: "インテリア関係資格",
            children: [],
          },
          {
            id: "001016008",
            name: "介護・福祉関係資格",
            children: [],
          },
          {
            id: "001016009",
            name: "技術・建築関係資格",
            children: [],
          },
          {
            id: "001016010",
            name: "語学関係資格",
            children: [],
          },
          {
            id: "001016011",
            name: "パソコン関係資格",
            children: [],
          },
          {
            id: "001016012",
            name: "旅行主任者",
            children: [],
          },
          {
            id: "001016013",
            name: "カラーコーディネーター・色彩検定",
            children: [],
          },
          {
            id: "001016014",
            name: "数学検定",
            children: [],
          },
          {
            id: "001016015",
            name: "公務員試験",
            children: [],
          },
          {
            id: "001016016",
            name: "自動車免許",
            children: [],
          },
          {
            id: "001016017",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001017",
        name: "ライトノベル",
        children: [
          {
            id: "001017005",
            name: "少年",
            children: [],
          },
          {
            id: "001017006",
            name: "少女",
            children: [],
          },
          {
            id: "001017004",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001018",
        name: "楽譜",
        children: [
          {
            id: "001018002",
            name: "ピアノ",
            children: [],
          },
          {
            id: "001018003",
            name: "ギター",
            children: [],
          },
          {
            id: "001018004",
            name: "管・打楽器",
            children: [],
          },
          {
            id: "001018005",
            name: "吹奏楽・アンサンブル・ミニチュアスコア",
            children: [],
          },
          {
            id: "001018006",
            name: "バイオリン・チェロ・コントラバス",
            children: [],
          },
          {
            id: "001018008",
            name: "LM（ライトミュージック）楽器教本",
            children: [],
          },
          {
            id: "001018007",
            name: "その他楽器",
            children: [],
          },
          {
            id: "001018009",
            name: "バンドスコア",
            children: [],
          },
          {
            id: "001018010",
            name: "合唱",
            children: [],
          },
          {
            id: "001018011",
            name: "声楽",
            children: [],
          },
          {
            id: "001018013",
            name: "ダウンロード楽譜",
            children: [],
          },
          {
            id: "001018012",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001019",
        name: "文庫",
        children: [
          {
            id: "001019001",
            name: "小説・エッセイ",
            children: [],
          },
          {
            id: "001019002",
            name: "美容・暮らし・健康・料理",
            children: [],
          },
          {
            id: "001019003",
            name: "ホビー・スポーツ・美術",
            children: [],
          },
          {
            id: "001019005",
            name: "語学・学習参考書",
            children: [],
          },
          {
            id: "001019006",
            name: "旅行・留学・アウトドア",
            children: [],
          },
          {
            id: "001019007",
            name: "人文・思想・社会",
            children: [],
          },
          {
            id: "001019008",
            name: "ビジネス・経済・就職",
            children: [],
          },
          {
            id: "001019009",
            name: "パソコン・システム開発",
            children: [],
          },
          {
            id: "001019010",
            name: "科学・医学・技術",
            children: [],
          },
          {
            id: "001019011",
            name: "漫画（コミック）",
            children: [],
          },
          {
            id: "001019012",
            name: "ライトノベル",
            children: [],
          },
          {
            id: "001019013",
            name: "エンタメ",
            children: [],
          },
          {
            id: "001019014",
            name: "写真集・タレント",
            children: [],
          },
          {
            id: "001019015",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001020",
        name: "新書",
        children: [
          {
            id: "001020001",
            name: "小説・エッセイ",
            children: [],
          },
          {
            id: "001020002",
            name: "美容・暮らし・健康・料理",
            children: [],
          },
          {
            id: "001020003",
            name: "ホビー・スポーツ・美術",
            children: [],
          },
          {
            id: "001020004",
            name: "絵本・児童書・図鑑",
            children: [],
          },
          {
            id: "001020005",
            name: "語学・学習参考書",
            children: [],
          },
          {
            id: "001020006",
            name: "旅行・留学・アウトドア",
            children: [],
          },
          {
            id: "001020007",
            name: "人文・思想・社会",
            children: [],
          },
          {
            id: "001020008",
            name: "ビジネス・経済・就職",
            children: [],
          },
          {
            id: "001020009",
            name: "パソコン・システム開発",
            children: [],
          },
          {
            id: "001020010",
            name: "科学・医学・技術",
            children: [],
          },
          {
            id: "001020011",
            name: "エンタメ",
            children: [],
          },
          {
            id: "001020014",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001021",
        name: "ボーイズラブ（BL）",
        children: [
          {
            id: "001021001",
            name: "小説",
            children: [],
          },
          {
            id: "001021002",
            name: "コミック",
            children: [],
          },
          {
            id: "001021003",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001028",
        name: "医学・薬学・看護学・歯科学",
        children: [
          {
            id: "001028001",
            name: "基礎医学",
            children: [],
          },
          {
            id: "001028002",
            name: "臨床医学一般",
            children: [],
          },
          {
            id: "001028003",
            name: "臨床医学内科系",
            children: [],
          },
          {
            id: "001028004",
            name: "臨床医学外科系",
            children: [],
          },
          {
            id: "001028005",
            name: "臨床医学専門科別",
            children: [],
          },
          {
            id: "001028006",
            name: "医学一般・社会医学",
            children: [],
          },
          {
            id: "001028007",
            name: "基礎看護学",
            children: [],
          },
          {
            id: "001028008",
            name: "臨床成人看護",
            children: [],
          },
          {
            id: "001028009",
            name: "臨床看護専門科別",
            children: [],
          },
          {
            id: "001028010",
            name: "臨床看護一般",
            children: [],
          },
          {
            id: "001028011",
            name: "看護学生向け",
            children: [],
          },
          {
            id: "001028012",
            name: "保健・助産",
            children: [],
          },
          {
            id: "001028013",
            name: "医療関連科学・技術",
            children: [],
          },
          {
            id: "001028014",
            name: "伝統医学・東洋医学",
            children: [],
          },
          {
            id: "001028015",
            name: "薬学",
            children: [],
          },
          {
            id: "001028016",
            name: "歯科医学",
            children: [],
          },
          {
            id: "001028017",
            name: "試験対策(資格試験別)",
            children: [],
          },
          {
            id: "001028018",
            name: "辞事典・白書・語学",
            children: [],
          },
          {
            id: "001028019",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "001029",
        name: "ティーンズラブ（TL）",
        children: [
          {
            id: "001029001",
            name: "小説",
            children: [],
          },
          {
            id: "001029002",
            name: "コミック",
            children: [],
          },
        ],
      },
    ],
  },
  {
    id: "005",
    name: "洋書",
    children: [
      {
        id: "005401",
        name: "Art & Entertainment（芸術＆エンターテインメント）",
        children: [
          {
            id: "005401001",
            name: "Antiques & Collectibles",
            children: [],
          },
          {
            id: "005401002",
            name: "Architecture",
            children: [],
          },
          {
            id: "005401003",
            name: "Art",
            children: [],
          },
          {
            id: "005401004",
            name: "Crafts & Hobbies",
            children: [],
          },
          {
            id: "005401005",
            name: "Drama",
            children: [],
          },
          {
            id: "005401006",
            name: "Recorded Music",
            children: [],
          },
          {
            id: "005401007",
            name: "Music",
            children: [],
          },
          {
            id: "005401008",
            name: "Performing Arts",
            children: [],
          },
          {
            id: "005401009",
            name: "Photography",
            children: [],
          },
          {
            id: "005401010",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005402",
        name: "Fiction & Literature（小説＆文芸）",
        children: [
          {
            id: "005402001",
            name: "Biography & Autobiography",
            children: [],
          },
          {
            id: "005402002",
            name: "Fiction",
            children: [],
          },
          {
            id: "005402003",
            name: "Humor",
            children: [],
          },
          {
            id: "005402004",
            name: "Literary Collections",
            children: [],
          },
          {
            id: "005402005",
            name: "Literary Criticism",
            children: [],
          },
          {
            id: "005402006",
            name: "Poetry",
            children: [],
          },
          {
            id: "005402007",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005403",
        name: "Business & Self-Culture（ビジネス）",
        children: [
          {
            id: "005403001",
            name: "Business & Economics",
            children: [],
          },
          {
            id: "005403002",
            name: "Self-Help",
            children: [],
          },
          {
            id: "005403003",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005404",
        name: "Family life & Comics（生活＆コミック）",
        children: [
          {
            id: "005404001",
            name: "Cooking",
            children: [],
          },
          {
            id: "005404002",
            name: "Education",
            children: [],
          },
          {
            id: "005404003",
            name: "Family & Relationships",
            children: [],
          },
          {
            id: "005404004",
            name: "Gardening",
            children: [],
          },
          {
            id: "005404005",
            name: "Health & Fitness",
            children: [],
          },
          {
            id: "005404006",
            name: "House & Home",
            children: [],
          },
          {
            id: "005404007",
            name: "Pets",
            children: [],
          },
          {
            id: "005404008",
            name: "Sports & Recreation",
            children: [],
          },
          {
            id: "005404009",
            name: "Comics & Graphic Novels",
            children: [],
          },
          {
            id: "005404010",
            name: "Games",
            children: [],
          },
          {
            id: "005404011",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005405",
        name: "Computers & Science（コンピューター＆科学）",
        children: [
          {
            id: "005405001",
            name: "Computers",
            children: [],
          },
          {
            id: "005405002",
            name: "Mathematics",
            children: [],
          },
          {
            id: "005405003",
            name: "Medical",
            children: [],
          },
          {
            id: "005405004",
            name: "Nature",
            children: [],
          },
          {
            id: "005405005",
            name: "Science",
            children: [],
          },
          {
            id: "005405006",
            name: "Technology",
            children: [],
          },
          {
            id: "005405007",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005406",
        name: "Social Science（社会科学）",
        children: [
          {
            id: "005406001",
            name: "History",
            children: [],
          },
          {
            id: "005406002",
            name: "Law",
            children: [],
          },
          {
            id: "005406003",
            name: "Body, Mind & Spirit",
            children: [],
          },
          {
            id: "005406004",
            name: "Philosophy",
            children: [],
          },
          {
            id: "005406005",
            name: "Political Science",
            children: [],
          },
          {
            id: "005406006",
            name: "Psychology",
            children: [],
          },
          {
            id: "005406007",
            name: "Religion",
            children: [],
          },
          {
            id: "005406008",
            name: "Social Science",
            children: [],
          },
          {
            id: "005406009",
            name: "True Crime",
            children: [],
          },
          {
            id: "005406010",
            name: "Language Arts & Disciplines",
            children: [],
          },
          {
            id: "005406011",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005407",
        name: "Books for kids（児童書）",
        children: [
          {
            id: "005407001",
            name: "Juvenile Nonfiction",
            children: [],
          },
          {
            id: "005407002",
            name: "Juvenile Fiction",
            children: [],
          },
          {
            id: "005407003",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005408",
        name: "Reference & Language（辞典＆語学）",
        children: [
          {
            id: "005408001",
            name: "Periodicals",
            children: [],
          },
          {
            id: "005408002",
            name: "Study Aids",
            children: [],
          },
          {
            id: "005408003",
            name: "Reference",
            children: [],
          },
          {
            id: "005408004",
            name: "Foreign Language Study",
            children: [],
          },
          {
            id: "005408005",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005409",
        name: "Travel（旅行）",
        children: [
          {
            id: "005409001",
            name: "Transportation",
            children: [],
          },
          {
            id: "005409002",
            name: "Travel",
            children: [],
          },
          {
            id: "005409003",
            name: "Non-Classifiable",
            children: [],
          },
        ],
      },
      {
        id: "005410",
        name: "Non-Classifiable（その他）",
        children: [],
      },
    ],
  },
  {
    id: "007",
    name: "雑誌",
    children: [
      {
        id: "007601",
        name: "総合誌",
        children: [],
      },
      {
        id: "007602",
        name: "文芸",
        children: [
          {
            id: "007602001",
            name: "小説誌",
            children: [],
          },
          {
            id: "007602002",
            name: "文芸誌",
            children: [],
          },
          {
            id: "007602004",
            name: "詩歌・俳句",
            children: [],
          },
        ],
      },
      {
        id: "007603",
        name: "専門誌",
        children: [
          {
            id: "007603001",
            name: "自然科学",
            children: [],
          },
          {
            id: "007603002",
            name: "工学",
            children: [],
          },
          {
            id: "007603003",
            name: "医学・看護",
            children: [],
          },
          {
            id: "007603004",
            name: "法律・社会",
            children: [],
          },
          {
            id: "007603005",
            name: "歴史・地理",
            children: [],
          },
          {
            id: "007603006",
            name: "教育語学",
            children: [],
          },
          {
            id: "007603007",
            name: "美術・デザイン",
            children: [],
          },
          {
            id: "007603008",
            name: "思想・宗教",
            children: [],
          },
          {
            id: "007603009",
            name: "教育",
            children: [],
          },
          {
            id: "007603010",
            name: "経済",
            children: [],
          },
          {
            id: "007603012",
            name: "建築",
            children: [],
          },
          {
            id: "007603011",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007604",
        name: "ビジネス・投資",
        children: [
          {
            id: "007604001",
            name: "ビジネス",
            children: [],
          },
          {
            id: "007604002",
            name: "マネー",
            children: [],
          },
          {
            id: "007604003",
            name: "社会・時局",
            children: [],
          },
          {
            id: "007604004",
            name: "商業",
            children: [],
          },
          {
            id: "007604005",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007605",
        name: "コンピューター誌",
        children: [
          {
            id: "007605001",
            name: "総合誌",
            children: [],
          },
          {
            id: "007605002",
            name: "WIN・DOS/V",
            children: [],
          },
          {
            id: "007605003",
            name: "MAC",
            children: [],
          },
          {
            id: "007605004",
            name: "通信・インターネット",
            children: [],
          },
          {
            id: "007605006",
            name: "CG・グラフィック",
            children: [],
          },
          {
            id: "007605007",
            name: "プログラミング",
            children: [],
          },
          {
            id: "007605008",
            name: "エンタメ",
            children: [],
          },
          {
            id: "007605009",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007606",
        name: "女性誌",
        children: [
          {
            id: "007606003",
            name: "女性ファッション",
            children: [],
          },
          {
            id: "007606004",
            name: "ストリートファッション",
            children: [],
          },
          {
            id: "007606005",
            name: "美容",
            children: [],
          },
          {
            id: "007606006",
            name: "情報誌",
            children: [],
          },
          {
            id: "007606008",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007607",
        name: "男性誌",
        children: [
          {
            id: "007607001",
            name: "ヤング",
            children: [],
          },
          {
            id: "007607002",
            name: "グルメ・趣味",
            children: [],
          },
          {
            id: "007607003",
            name: "男性ファッション",
            children: [],
          },
          {
            id: "007607004",
            name: "モノ情報誌",
            children: [],
          },
          {
            id: "007607005",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007608",
        name: "生活・健康",
        children: [
          {
            id: "007608001",
            name: "総合",
            children: [],
          },
          {
            id: "007608003",
            name: "健康・家庭医学",
            children: [],
          },
          {
            id: "007608004",
            name: "住宅",
            children: [],
          },
          {
            id: "007608005",
            name: "インテリア",
            children: [],
          },
          {
            id: "007608006",
            name: "ライフスタイル",
            children: [],
          },
          {
            id: "007608007",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007609",
        name: "情報誌",
        children: [
          {
            id: "007609001",
            name: "タウン情報",
            children: [],
          },
          {
            id: "007609002",
            name: "就職・求人情報",
            children: [],
          },
          {
            id: "007609004",
            name: "住宅情報",
            children: [],
          },
          {
            id: "007609005",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007610",
        name: "結婚・出産・子育て",
        children: [
          {
            id: "007610001",
            name: "結婚",
            children: [],
          },
          {
            id: "007610002",
            name: "出産",
            children: [],
          },
          {
            id: "007610003",
            name: "育児",
            children: [],
          },
          {
            id: "007610004",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007611",
        name: "旅行・アウトドア",
        children: [
          {
            id: "007611001",
            name: "旅行誌",
            children: [],
          },
          {
            id: "007611002",
            name: "時刻表",
            children: [],
          },
          {
            id: "007611004",
            name: "タウン情報",
            children: [],
          },
          {
            id: "007611005",
            name: "エリア別",
            children: [],
          },
          {
            id: "007611006",
            name: "目的別",
            children: [],
          },
          {
            id: "007611007",
            name: "海外",
            children: [],
          },
          {
            id: "007611008",
            name: "アウトドア",
            children: [],
          },
          {
            id: "007611009",
            name: "登山・ハイキング",
            children: [],
          },
          {
            id: "007611010",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007612",
        name: "料理・グルメ情報",
        children: [
          {
            id: "007612001",
            name: "料理全般",
            children: [],
          },
          {
            id: "007612002",
            name: "グルメ情報",
            children: [],
          },
          {
            id: "007612004",
            name: "おかず",
            children: [],
          },
          {
            id: "007612005",
            name: "パン・ケーキ・お菓子",
            children: [],
          },
          {
            id: "007612006",
            name: "酒",
            children: [],
          },
          {
            id: "007612007",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007613",
        name: "手芸・編物",
        children: [
          {
            id: "007613001",
            name: "服飾",
            children: [],
          },
          {
            id: "007613002",
            name: "手芸",
            children: [],
          },
          {
            id: "007613003",
            name: "編物",
            children: [],
          },
          {
            id: "007613004",
            name: "パッチワーク",
            children: [],
          },
          {
            id: "007613005",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007614",
        name: "スポーツ誌",
        children: [
          {
            id: "007614001",
            name: "総合",
            children: [],
          },
          {
            id: "007614002",
            name: "ゴルフ",
            children: [],
          },
          {
            id: "007614003",
            name: "フィールド競技",
            children: [],
          },
          {
            id: "007614004",
            name: "マリンスポーツ",
            children: [],
          },
          {
            id: "007614005",
            name: "格闘技",
            children: [],
          },
          {
            id: "007614006",
            name: "屋内スポーツ",
            children: [],
          },
          {
            id: "007614007",
            name: "ウインタースポーツ",
            children: [],
          },
          {
            id: "007614008",
            name: "フィットネス",
            children: [],
          },
          {
            id: "007614009",
            name: "野球",
            children: [],
          },
          {
            id: "007614010",
            name: "サッカー",
            children: [],
          },
          {
            id: "007614011",
            name: "テニス",
            children: [],
          },
          {
            id: "007614012",
            name: "ダンス",
            children: [],
          },
          {
            id: "007614013",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007615",
        name: "テキスト",
        children: [
          {
            id: "007615001",
            name: "語学テキスト",
            children: [],
          },
          {
            id: "007615002",
            name: "語学カセット・ＣＤ",
            children: [],
          },
          {
            id: "007615003",
            name: "テレビ趣味講座",
            children: [],
          },
          {
            id: "007615004",
            name: "テレビ教育講座",
            children: [],
          },
          {
            id: "007615005",
            name: "語学一般",
            children: [],
          },
          {
            id: "007615006",
            name: "進学誌",
            children: [],
          },
          {
            id: "007615007",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007616",
        name: "幼児・児童・絵本",
        children: [
          {
            id: "007616001",
            name: "幼児",
            children: [],
          },
          {
            id: "007616004",
            name: "絵本",
            children: [],
          },
          {
            id: "007616006",
            name: "児童",
            children: [],
          },
          {
            id: "007616005",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007617",
        name: "音楽・芸能",
        children: [
          {
            id: "007617001",
            name: "音楽",
            children: [],
          },
          {
            id: "007617002",
            name: "アイドル",
            children: [],
          },
          {
            id: "007617003",
            name: "芸能・映画",
            children: [],
          },
          {
            id: "007617004",
            name: "テレビ情報",
            children: [],
          },
          {
            id: "007617007",
            name: "大衆・娯楽",
            children: [],
          },
          {
            id: "007617008",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007618",
        name: "コミック・アニメ",
        children: [
          {
            id: "007618001",
            name: "青年",
            children: [],
          },
          {
            id: "007618002",
            name: "4コマ",
            children: [],
          },
          {
            id: "007618003",
            name: "少年",
            children: [],
          },
          {
            id: "007618007",
            name: "少女",
            children: [],
          },
          {
            id: "007618008",
            name: "レディース",
            children: [],
          },
          {
            id: "007618009",
            name: "アニメ",
            children: [],
          },
          {
            id: "007618010",
            name: "声優",
            children: [],
          },
          {
            id: "007618011",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007619",
        name: "ゲーム",
        children: [
          {
            id: "007619001",
            name: "ソフトデータ誌",
            children: [],
          },
          {
            id: "007619002",
            name: "攻略誌",
            children: [],
          },
          {
            id: "007619003",
            name: "アーケード",
            children: [],
          },
          {
            id: "007619004",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007620",
        name: "車・バイク",
        children: [
          {
            id: "007620001",
            name: "バイク",
            children: [],
          },
          {
            id: "007620002",
            name: "車総合",
            children: [],
          },
          {
            id: "007620003",
            name: "中古車情報",
            children: [],
          },
          {
            id: "007620004",
            name: "外車情報",
            children: [],
          },
          {
            id: "007620005",
            name: "モータースポーツ",
            children: [],
          },
          {
            id: "007620006",
            name: "4WD・RV",
            children: [],
          },
          {
            id: "007620007",
            name: "チューニング・ドレスアップ",
            children: [],
          },
          {
            id: "007620008",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007621",
        name: "趣味",
        children: [
          {
            id: "007621001",
            name: "カメラ・ビデオ",
            children: [],
          },
          {
            id: "007621002",
            name: "園芸",
            children: [],
          },
          {
            id: "007621003",
            name: "書道・茶道・華道",
            children: [],
          },
          {
            id: "007621004",
            name: "フラワーアレンジメント",
            children: [],
          },
          {
            id: "007621005",
            name: "パズルゲーム・クイズ",
            children: [],
          },
          {
            id: "007621006",
            name: "ペット・動物",
            children: [],
          },
          {
            id: "007621007",
            name: "パチンコ・パチスロ",
            children: [],
          },
          {
            id: "007621008",
            name: "ギャンブル",
            children: [],
          },
          {
            id: "007621009",
            name: "ホビー・サイエンス",
            children: [],
          },
          {
            id: "007621010",
            name: "アウトドア",
            children: [],
          },
          {
            id: "007621011",
            name: "釣り",
            children: [],
          },
          {
            id: "007621012",
            name: "くじ・懸賞",
            children: [],
          },
          {
            id: "007621013",
            name: "競馬",
            children: [],
          },
          {
            id: "007621014",
            name: "ミリタリー",
            children: [],
          },
          {
            id: "007621015",
            name: "ラジコン・無線",
            children: [],
          },
          {
            id: "007621016",
            name: "鉄道",
            children: [],
          },
          {
            id: "007621017",
            name: "オーディオ・ビジュアル",
            children: [],
          },
          {
            id: "007621018",
            name: "囲碁・将棋",
            children: [],
          },
          {
            id: "007621019",
            name: "その他",
            children: [],
          },
        ],
      },
      {
        id: "007625",
        name: "ティーンズ",
        children: [],
      },
      {
        id: "007627",
        name: "資格・求人・キャリア",
        children: [],
      },
      {
        id: "007628",
        name: "携帯電話・スマートフォン・アプリ",
        children: [],
      },
      {
        id: "007629",
        name: "ボーイズラブ（BL）",
        children: [],
      },
      {
        id: "007623",
        name: "その他",
        children: [],
      },
    ],
  },
] as const;

export const DefaultDeniedGenreIds = [
  "001001003",
  "001001004",
  "001001006",
  "001001012",
  "001010014",
  "001013001",
  "001013002",
  "001018",
  "001019011",
  "001019014",
  "001021",
  "001026",
  "001027",
  "001029",
  "005404009",
  "007601",
  "007606",
  "007607",
  "007617002",
  "007617003",
  "007617007",
  "007617008",
  "007618001",
  "007618008",
  "007618011",
  "007621007",
  "007621008",
  "007625",
  "007629",
] as const;
