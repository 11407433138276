import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { Info as InfoModel } from "../common/model";
import getAxiosInstance from "../common/Axios";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Pagination,
  Stack,
  Typography,
} from "@mui/material";
import { getDisplayedDatetime } from "../common/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import commonScss from "../assets/scss/common.module.scss";

const Info: React.FC = () => {
  const { user } = useContext(Context);
  const [informationList, setInformationList] = useState<InfoModel[]>([]);
  const [areLoaded, setAreLoaded] = useState(false);
  const [count, setCount] = useState(1);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (!areLoaded) {
      (async () => {
        const axiosInstance = await getAxiosInstance();
        axiosInstance
          .get("/info")
          .then((response) => {
            const responseData = response.data as InfoModel[];
            const sortedInformationList = responseData.sort((a, b) => {
              const aTime = new Date(a.createdAt).getTime();
              const bTime = new Date(b.createdAt).getTime();
              if (aTime > bTime) {
                return -1;
              } else if (bTime < aTime) {
                return 1;
              } else {
                return 0;
              }
            });
            setInformationList(sortedInformationList);
            setAreLoaded(true);
          })
          .catch((error) => {
            console.log(error);
          });
      })();
    }
  }, [areLoaded]);

  useEffect(() => {
    setCount(Math.max(Math.ceil(informationList.length / 5), 1));
  }, [informationList]);

  const [listedInformationList, setListedInformationList] =
    useState(informationList);
  useEffect(() => {
    setListedInformationList(informationList.slice(5 * (page - 1), 5 * page));
  }, [informationList, page]);

  return (
    <>
      {!user && informationList.length > 0 && (
        <Stack alignItems="center" gap={2} direction="column">
          <br />
          <Typography>最新情報</Typography>
          <Pagination
            count={count}
            page={page}
            onChange={(_, newPage) => {
              setPage(newPage);
            }}
          />
          {listedInformationList.map((info) => (
            <Accordion key={info.dataId} className={commonScss.fullWidth}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Stack gap={2} direction="row" alignItems="center">
                  {info.isImportant && (
                    <Chip label="重要" color="primary" variant="outlined" />
                  )}
                  <Typography>
                    {info.subject} {getDisplayedDatetime(info.createdAt)}
                  </Typography>
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <span className={commonScss.preWrap}>{info.webMessage}</span>
              </AccordionDetails>
            </Accordion>
          ))}
        </Stack>
      )}
    </>
  );
};

export default Info;
