import React from "react";
import ReactDOM from "react-dom/client";
import { AwsRum, AwsRumConfig } from "aws-rum-web";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@aws-amplify/ui-react/styles.css";
import "./assets/scss/index.module.scss";

export let awsRum: AwsRum | undefined;
try {
  const config: AwsRumConfig = {
    sessionSampleRate: 1,
    guestRoleArn: process.env.REACT_APP_RUM_GUEST_ROLE_ARN,
    identityPoolId: process.env.REACT_APP_RUM_IDENTITY_POOL_ID,
    endpoint: process.env.REACT_APP_RUM_ENDPOINT,
    telemetries: ["performance", "errors", "http"],
    allowCookies: false,
    enableXRay: false,
  };

  awsRum = new AwsRum(
    process.env.REACT_APP_RUM_APPLICATION_ID!,
    process.env.REACT_APP_RUM_APPLICATION_VERSION!,
    process.env.REACT_APP_RUM_APPLICATION_REGION!,
    config
  );
} catch (error) {
  // Ignore errors thrown during CloudWatch RUM web client initialization
}

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
