import { Button, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import { updatePassword } from "aws-amplify/auth";
import { handleError, validatePassword } from "../../common/utils";

const Password: React.FC = () => {
  const { addLoadingProcess, deleteLoadingProcess, loadUserAttributes } =
    useContext(Context);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [newPasswordError, setNewPasswordError] = useState<
    string | undefined
  >();
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (newPassword.length !== 0) {
      if (newPassword === oldPassword) {
        setNewPasswordError(
          "現在のパスワードとは別のパスワードを指定してください"
        );
      } else {
        setNewPasswordError(validatePassword(newPassword));
      }
    } else {
      setNewPasswordError(undefined);
    }
  }, [newPassword, oldPassword]);
  useEffect(() => {
    setConfirmPasswordError(
      newPassword === confirmPassword
        ? undefined
        : "新しいパスワードが一致していません"
    );
  }, [newPassword, confirmPassword]);

  return (
    <>
      <Stack direction="column" alignItems="center" gap={2}>
        <TextField
          value={oldPassword}
          label="現在のパスワード"
          type="password"
          variant="standard"
          fullWidth
          required
          onChange={(e) => {
            setOldPassword(e.target.value);
          }}
        />
        <TextField
          value={newPassword}
          label="新しいパスワード"
          type="password"
          variant="standard"
          fullWidth
          required
          error={!!newPasswordError}
          helperText={newPasswordError}
          onChange={(e) => {
            setNewPassword(e.target.value);
          }}
        />
        <TextField
          value={confirmPassword}
          label="新しいパスワード(確認)"
          type="password"
          variant="standard"
          fullWidth
          required
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
        />
        <Button
          onClick={() => {
            const processId = addLoadingProcess();
            updatePassword({ oldPassword, newPassword })
              .then(() => {
                alert("パスワードが更新されました");
                setOldPassword("");
                setNewPassword("");
                setConfirmPassword("");
              })
              .catch((error) => {
                handleError(error);
              })
              .finally(() => {
                deleteLoadingProcess(processId);
                loadUserAttributes();
              });
          }}
          disabled={
            !oldPassword ||
            !newPassword ||
            !confirmPassword ||
            !!newPasswordError ||
            !!confirmPasswordError
          }
        >
          変更
        </Button>
      </Stack>
    </>
  );
};

export default Password;
