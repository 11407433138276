import { Button, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../App";
import { confirmResetPassword, resetPassword } from "aws-amplify/auth";
import { handleError, validatePassword } from "../../common/utils";

const ResetPassword: React.FC = () => {
  const { addLoadingProcess, deleteLoadingProcess, setIsResettingPassword } =
    useContext(Context);
  const [username, setUsername] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isConfirming, setIsConfirming] = useState(false);

  const [newPasswordError, setNewPasswordError] = useState<
    string | undefined
  >();
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (newPassword.length !== 0) {
      setNewPasswordError(validatePassword(newPassword));
    }
  }, [newPassword]);
  useEffect(() => {
    setConfirmPasswordError(
      newPassword === confirmPassword
        ? undefined
        : "新しいパスワードが一致していません"
    );
  }, [newPassword, confirmPassword]);

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      {!isConfirming && (
        <>
          <TextField
            value={username}
            label="ユーザー名"
            variant="standard"
            fullWidth
            required
            onChange={(e) => {
              setUsername(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              const processId = addLoadingProcess();
              resetPassword({ username })
                .then(({ nextStep }) => {
                  switch (nextStep.resetPasswordStep) {
                    case "CONFIRM_RESET_PASSWORD_WITH_CODE":
                      alert(
                        `確認コードが${nextStep.codeDeliveryDetails.deliveryMedium}に送信されました`
                      );
                      setIsConfirming(true);
                      break;
                    case "DONE":
                      alert("パスワードが無事にリセットされました");
                      setIsResettingPassword(false);
                      break;
                    default:
                      break;
                  }
                })
                .catch((error) => {
                  handleError(error);
                })
                .finally(() => {
                  deleteLoadingProcess(processId);
                });
            }}
            disabled={!username}
            variant="contained"
          >
            パスワードリセット用確認コードの送信
          </Button>
        </>
      )}
      {isConfirming && (
        <>
          <TextField
            value={confirmationCode}
            label="確認コード"
            fullWidth
            required
            variant="standard"
            onChange={(e) => {
              setConfirmationCode(e.target.value);
            }}
          />
          <TextField
            value={newPassword}
            label="新しいパスワード"
            type="password"
            variant="standard"
            fullWidth
            required
            error={!!newPasswordError}
            helperText={newPasswordError}
            onChange={(e) => {
              setNewPassword(e.target.value);
            }}
          />
          <TextField
            value={confirmPassword}
            label="新しいパスワード(確認)"
            type="password"
            variant="standard"
            fullWidth
            required
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            error={!!confirmPasswordError}
            helperText={confirmPasswordError}
          />
          <Button
            onClick={() => {
              const processId = addLoadingProcess();
              confirmResetPassword({ confirmationCode, username, newPassword })
                .then(() => {
                  alert("パスワードが更新されました");
                  setConfirmationCode("");
                  setNewPassword("");
                  setConfirmPassword("");
                  setIsResettingPassword(false);
                })
                .catch((error) => {
                  handleError(error);
                })
                .finally(() => {
                  deleteLoadingProcess(processId);
                });
            }}
            disabled={
              !confirmationCode ||
              !newPassword ||
              !confirmPassword ||
              !!newPasswordError ||
              !!confirmPasswordError
            }
            variant="contained"
          >
            パスワードリセット
          </Button>
        </>
      )}
      <Button
        color="secondary"
        onClick={() => {
          setIsConfirming(false);
          setIsResettingPassword(false);
        }}
      >
        サインインに戻る
      </Button>
    </Stack>
  );
};

export default ResetPassword;
