import { Button, Stack, TextField } from "@mui/material";
import React, { useContext, useState } from "react";
import { Context } from "../../App";
import { signIn } from "aws-amplify/auth";
import { handleError } from "../../common/utils";

interface Props {
  setSigninUsername: (value: string) => void;
}

const SingIn: React.FC<Props> = (props) => {
  const { setSigninUsername } = props;
  const {
    addLoadingProcess,
    deleteLoadingProcess,
    setIsConfirmingSignUp,
    setIsResettingPassword,
  } = useContext(Context);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <TextField
        value={username}
        label="ユーザー名"
        variant="standard"
        fullWidth
        required
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <TextField
        value={password}
        label="パスワード"
        type="password"
        variant="standard"
        fullWidth
        required
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <Button
        onClick={() => {
          const processId = addLoadingProcess();
          signIn({ username, password })
            .then(({ isSignedIn, nextStep }) => {
              const signinUsername = username;
              if (isSignedIn) {
                setUsername("");
                setPassword("");
              }
              setSigninUsername(signinUsername);
              switch (nextStep.signInStep) {
                case "CONFIRM_SIGN_UP":
                  setIsConfirmingSignUp(true);
                  alert("確認コードを入力しアカウント登録を完了してください");
                  break;
                case "RESET_PASSWORD":
                  setIsResettingPassword(true);
                  alert("パスワードのリセットが必要です");
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              handleError(error);
            })
            .finally(() => {
              deleteLoadingProcess(processId);
            });
        }}
        disabled={!username || !password}
        variant="contained"
      >
        サインイン
      </Button>
      <Button
        color="secondary"
        onClick={() => {
          setIsResettingPassword(true);
        }}
      >
        パスワードを忘れた
      </Button>
    </Stack>
  );
};

export default SingIn;
