import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import { handleError, move } from "../common/utils";
import { DefaultDeniedGenreIds } from "../common/const";
import { Book, SavedBook } from "../common/model";
import { Button, ImageList, Stack } from "@mui/material";
import BookItem from "../components/BookItem";
import commonScss from "../assets/scss/common.module.scss";
import SearchIcon from "@mui/icons-material/Search";
import { useNavigate } from "react-router-dom";
import { updateUserAttribute } from "aws-amplify/auth";

export const Recommended: React.FC = () => {
  const {
    books,
    userAttributes,
    recommendation,
    isNewRecommendation,
    loadRecommendation,
    loadUserAttributes,
  } = useContext(Context);
  const navigate = useNavigate();
  const [deniedGenreIds, setDeniedGenreIds] = useState<string[]>([
    ...DefaultDeniedGenreIds,
  ]);
  useEffect(() => {
    if (userAttributes) {
      setDeniedGenreIds(
        userAttributes["custom:deniedGenreIds"]
          ? JSON.parse(userAttributes["custom:deniedGenreIds"])
          : [...DefaultDeniedGenreIds]
      );
    }
  }, [userAttributes]);

  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (!isLoaded) {
      loadRecommendation().then(() => {
        setIsLoaded(true);
      });
    }
  }, [loadRecommendation, isLoaded]);

  const [displayedBooks, setDisplayedBooks] = useState<Book[]>([]);
  const [scores, setScores] = useState<number[]>([]);
  useEffect(() => {
    const result: Book[] = [];
    const scoresResult: number[] = [];
    if (recommendation) {
      recommendation.list.every((recommended) => {
        let recommendable = true;
        if (
          books.map((book) => book.isbnjan).includes(recommended.book.isbnjan)
        ) {
          recommendable = false;
        }
        recommended.book.booksGenreId.every((genreId) => {
          deniedGenreIds.every((deniedGenreId) => {
            if (genreId.startsWith(deniedGenreId)) {
              recommendable = false;
            }
            return recommendable;
          });
          return recommendable;
        });
        if (recommendable) {
          result.push(recommended.book);
          scoresResult.push(recommended.score);
        }
        return result.length < 100;
      });
    }
    setDisplayedBooks(result);
    setScores(scoresResult);
  }, [recommendation, deniedGenreIds, books]);

  const [readBooks, setReadBooks] = useState<SavedBook[]>([]);
  useEffect(() => {
    const result: SavedBook[] = [];
    books.forEach((book) => {
      if (book.isRead) {
        result.push(book);
      }
    });
    setReadBooks(result);
  }, [books]);

  useEffect(() => {
    if (isNewRecommendation && recommendation) {
      updateUserAttribute({
        userAttribute: {
          attributeKey: "custom:lastViewedRPId",
          value: recommendation.recommendationProcessId,
        },
      })
        .then(() => {
          loadUserAttributes();
        })
        .catch((error) => {
          handleError(error);
        });
    }
  }, [recommendation, isNewRecommendation, loadUserAttributes]);

  return (
    <>
      <Stack direction="column" alignItems="center" gap={2}>
        <span>
          おすすめ情報に関する設定は
          <Button
            onClick={() => {
              move(navigate, "/account");
            }}
          >
            データの取扱設定
          </Button>
          から変更できます
        </span>
        {readBooks.length < 5 && (
          <>
            <span className={commonScss.textCenter}>
              おすすめ情報が作成されるには、5点以上の本が読了リストに登録される必要がございます
            </span>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                move(navigate, "/search");
              }}
              startIcon={<SearchIcon />}
            >
              本を探す
            </Button>
          </>
        )}
        {readBooks.length >= 5 && (
          <>
            {displayedBooks.length > 0 && (
              <>
                <span className={commonScss.textCenter}>
                  普段お読みにならなそうな本を敢えておすすめいたします
                  <br />
                  フィルターバブル解消を目指しましょう
                </span>
                <ImageList cols={4}>
                  {displayedBooks.map((book, index) => {
                    return (
                      <BookItem
                        key={book.isbnjan}
                        book={book}
                        openBookDialogEvent={{
                          path: window.location.pathname,
                          type: "recommended",
                          score: scores[index],
                        }}
                      />
                    );
                  })}
                </ImageList>
              </>
            )}
            {displayedBooks.length === 0 && (
              <span className={commonScss.textCenter}>
                おすすめ情報が作成されるまで少々お待ちください
                <br />
                (データの取扱設定で自分に対するおすすめ情報が作成・更新されることに同意する必要があります)
              </span>
            )}
          </>
        )}
      </Stack>
    </>
  );
};

export default Recommended;
