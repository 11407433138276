import React, { useContext, useState } from "react";
import { Context } from "../../App";
import { Tab, Tabs } from "@mui/material";
import SingIn from "./SiginIn";
import CommonGrid from "../CommonGrid";
import ConfirmSignUp from "./ConfirmSignUp";
import ResetPassword from "./ResetPassword";
import SignUp from "./SignUp";

const Auth: React.FC = () => {
  const { user, isConfirmingSignUp, isResettingPassword } = useContext(Context);
  const [tab, setTab] = useState<"signIn" | "signUp">("signIn");
  const [signinUsername, setSigninUsername] = useState("");

  return (
    <>
      {(!user || isConfirmingSignUp || isResettingPassword) && (
        <>
          <Tabs
            value={tab}
            onChange={(_, value) => {
              setTab(value);
            }}
            variant="fullWidth"
          >
            <Tab value="signIn" label="サインイン" />
            <Tab value="signUp" label="新規登録" />
          </Tabs>
          <br />
        </>
      )}
      {!user && !isConfirmingSignUp && !isResettingPassword && (
        <CommonGrid>
          {tab === "signIn" && <SingIn setSigninUsername={setSigninUsername} />}
          {tab === "signUp" && <SignUp setSigninUsername={setSigninUsername} />}
        </CommonGrid>
      )}
      <CommonGrid>
        {isConfirmingSignUp && (
          <ConfirmSignUp signinUsername={signinUsername} />
        )}
        {isResettingPassword && <ResetPassword />}
      </CommonGrid>
    </>
  );
};

export default Auth;
