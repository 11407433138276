import React from "react";
import { Message } from "../../common/model";
import { Button, Chip, Stack, TableCell, TableRow } from "@mui/material";
import { getDisplayedDatetime } from "../../common/utils";

interface Props {
  message: Message;
  index: number;
  setIndex: (value: number | undefined) => void;
}

const MessageRow: React.FC<Props> = (props) => {
  const { message, index, setIndex } = props;

  return (
    <TableRow>
      <TableCell>
        <Stack gap={2} direction="row" alignItems="center">
          {message.subject}
          {!message.isRead && <Chip label="未読" variant="outlined" />}
          {message.isImportant && (
            <Chip label="重要" color="primary" variant="outlined" />
          )}
          <Button
            onClick={() => {
              setIndex(index);
            }}
          >
            開く
          </Button>
        </Stack>
      </TableCell>
      <TableCell>{getDisplayedDatetime(message.createdAt)}</TableCell>
    </TableRow>
  );
};

export default MessageRow;
