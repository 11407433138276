import {
  Tab,
  Tabs,
  ImageList,
  Stack,
  Button,
  Select,
  MenuItem,
  Badge,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import BookItem from "../components/BookItem";
import { Context } from "../App";
import { move } from "../common/utils";
import { BookIndexName } from "../common/const";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";
import RecommendIcon from "@mui/icons-material/Recommend";
import { SavedBook } from "../common/model";

const Bookshelf: React.FC = () => {
  const [isRead, setIsRead] = useState(true);
  const {
    books,
    setBookIndexName: setContextBookName,
    isNewRecommendation,
    loadBooks,
  } = useContext(Context);
  const [isLoaded, setIsLoaded] = useState(false);
  const navigate = useNavigate();
  const [bookIndexName, setBookIndexName] = useState(BookIndexName.author);

  useEffect(() => {
    if (!isLoaded) {
      loadBooks().then(() => {
        setIsLoaded(true);
      });
    }
  }, [isLoaded, loadBooks]);

  useEffect(() => {
    setContextBookName(isRead ? bookIndexName : BookIndexName.author);
  }, [isRead, setContextBookName, bookIndexName]);

  const [displayedBooks, setDisplayedBooks] = useState<SavedBook[]>([]);
  useEffect(() => {
    const newDisplayedBooks: SavedBook[] = [];
    books.forEach((book) => {
      if (book.isRead === isRead) {
        newDisplayedBooks.push(book);
      }
    });
    setDisplayedBooks(newDisplayedBooks);
  }, [books, isRead]);

  return (
    <>
      <Tabs value={isRead ? "isRead" : "isNotRead"} variant="fullWidth">
        <Tab
          value={"isRead"}
          label="読了"
          onClick={() => {
            setIsRead(true);
          }}
        />
        <Tab
          value={"isNotRead"}
          label="読みたい"
          onClick={() => {
            setIsRead(false);
          }}
        />
      </Tabs>
      <br />
      <Stack direction="column" alignItems="center" gap={2}>
        {displayedBooks.length === 0 && (
          <span>
            まだ本が登録されていません。本を探したり、おすすめを閲覧してみてください。
          </span>
        )}
        <Stack direction="row" alignItems="center" gap={2}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              move(navigate, "/search");
            }}
            startIcon={<SearchIcon />}
          >
            本を探す
          </Button>
          <Badge
            color="error"
            badgeContent={isNewRecommendation ? "NEW" : undefined}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                move(navigate, "/recommended");
              }}
              startIcon={<RecommendIcon />}
            >
              おすすめ
            </Button>
          </Badge>
        </Stack>
        {isRead && (
          <Select
            value={bookIndexName}
            onChange={(e) => {
              setBookIndexName(e.target.value as BookIndexName);
            }}
          >
            <MenuItem value={BookIndexName.author}>著者名順</MenuItem>
            <MenuItem value={BookIndexName.title}>タイトル順</MenuItem>
            <MenuItem value={BookIndexName.bookReadAt}>読了日順</MenuItem>
            <MenuItem value={BookIndexName.bookCreatedAt}>登録日順</MenuItem>
          </Select>
        )}
        <ImageList cols={4}>
          {displayedBooks.map((book) => {
            return (
              <BookItem
                key={book.isbnjan}
                book={book}
                openBookDialogEvent={{
                  path: window.location.pathname,
                  type: isRead ? "read" : "unread",
                  score: 0,
                }}
              />
            );
          })}
        </ImageList>
      </Stack>
    </>
  );
};

export default Bookshelf;
