import { Button, Stack, TextField } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../App";
import getAxiosInstance from "../common/Axios";
import { handleError } from "../common/utils";
import { ApiBook } from "../common/model";
import ApiBooks from "../components/ApiBooks";
import BarCodeScanner from "../components/BarCodeScanner";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import AbcIcon from "@mui/icons-material/Abc";

const Search: React.FC = () => {
  const [keyword, setKeyword] = useState("");
  const [searchedKeyword, setSearchedKeyword] = useState("");
  const { addLoadingProcess, deleteLoadingProcess } = useContext(Context);
  const [books, setBooks] = useState<ApiBook[]>([]);
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState(0);
  const [isScanning, setIsScanning] = useState(false);
  const [scrollTrigger, setScrolltrigger] = useState(false);

  useEffect(() => {
    if (isScanning) {
      window.scrollTo({
        top: 0,
        left: 0,
      });
      setTimeout(() => {
        setScrolltrigger(!scrollTrigger);
      }, 0);
    }
  }, [isScanning, scrollTrigger]);

  return (
    <>
      <Stack gap={2} alignItems="center">
        {!isScanning && (
          <TextField
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
            }}
            placeholder="タイトル、著者、ISBNコード..."
            fullWidth
          />
        )}
        {isScanning && (
          <BarCodeScanner
            setIsScanning={setIsScanning}
            setKeyword={setKeyword}
          />
        )}
        <Stack gap={2} direction="row" alignItems="center">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => {
              setIsScanning(!isScanning);
              setKeyword("");
            }}
            startIcon={isScanning ? <AbcIcon /> : <PhotoCameraIcon />}
          >
            {isScanning ? "キーワード検索" : "バーコード読み取り"}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={async () => {
              const processId = addLoadingProcess();
              const axiosInstance = await getAxiosInstance();
              axiosInstance
                .get("/rakutenApi", {
                  params: {
                    keyword,
                    page: 1,
                  },
                })
                .then((response) => {
                  const { books: apiBooks, lastPage: apiLastPage } =
                    response.data;
                  setBooks(apiBooks);
                  setPage(1);
                  setLastPage(apiLastPage);
                  setSearchedKeyword(keyword);
                })
                .catch((error) => {
                  handleError(error);
                })
                .finally(() => {
                  deleteLoadingProcess(processId);
                });
            }}
            disabled={!keyword}
          >
            検索
          </Button>
        </Stack>
      </Stack>
      <br />
      <ApiBooks books={books} />
      {page < lastPage && page > 0 && (
        <Stack direction="row" alignItems="center">
          <div style={{ marginLeft: "auto" }}></div>
          <Button
            color="secondary"
            onClick={async () => {
              const processId = addLoadingProcess();
              const axiosInstance = await getAxiosInstance();
              const nextPage = page + 1;
              axiosInstance
                .get("/rakutenApi", {
                  params: {
                    keyword: searchedKeyword,
                    page: nextPage,
                  },
                })
                .then((response) => {
                  const { books: apiBooks } = response.data;
                  setBooks([...books, ...apiBooks]);
                  setPage(nextPage);
                })
                .catch((error) => {
                  handleError(error);
                })
                .finally(() => {
                  deleteLoadingProcess(processId);
                });
            }}
          >
            更に読み込む
          </Button>
          <div style={{ marginRight: "auto" }}></div>
        </Stack>
      )}
    </>
  );
};

export default Search;
