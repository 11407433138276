import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { handleError, validatePassword } from "../../common/utils";
import { Context } from "../../App";
import { autoSignIn, signUp } from "aws-amplify/auth";
import { DefaultDeniedGenreIds } from "../../common/const";

interface Props {
  setSigninUsername: (value: string) => void;
}

const SignUp: React.FC<Props> = (props) => {
  const { setSigninUsername } = props;
  const { addLoadingProcess, deleteLoadingProcess, setIsConfirmingSignUp } =
    useContext(Context);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setNewPasswordError] = useState<string | undefined>();
  const [confirmPasswordError, setConfirmPasswordError] = useState<
    string | undefined
  >();
  useEffect(() => {
    if (password.length !== 0) {
      setNewPasswordError(validatePassword(password));
    }
  }, [password]);
  useEffect(() => {
    setConfirmPasswordError(
      password === confirmPassword
        ? undefined
        : "新しいパスワードが一致していません"
    );
  }, [password, confirmPassword]);

  const [acknowledgement, setAcknowledgement] = useState(false);

  return (
    <Stack direction="column" alignItems="center" gap={2}>
      <TextField
        value={username}
        label="ユーザー名(※変更不可)"
        variant="standard"
        fullWidth
        required
        onChange={(e) => {
          setUsername(e.target.value);
        }}
      />
      <TextField
        value={email}
        label="メールアドレス(※変更不可)"
        variant="standard"
        type="email"
        fullWidth
        required
        onChange={(e) => {
          setEmail(e.target.value);
        }}
      />
      <TextField
        value={password}
        label="パスワード"
        type="password"
        variant="standard"
        fullWidth
        required
        error={!!passwordError}
        helperText={passwordError}
        onChange={(e) => {
          setPassword(e.target.value);
        }}
      />
      <TextField
        value={confirmPassword}
        label="パスワード(確認)"
        type="password"
        variant="standard"
        fullWidth
        required
        onChange={(e) => {
          setConfirmPassword(e.target.value);
        }}
        error={!!confirmPasswordError}
        helperText={confirmPasswordError}
      />
      <Stack direction="column" gap={2}>
        <FormControlLabel
          control={
            <Checkbox
              checked={acknowledgement}
              onChange={(_, checked) => {
                setAcknowledgement(checked);
              }}
            />
          }
          required
          label={
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.PUBLIC_URL}/pdf/terms.pdf`}
              >
                利用規約
              </a>
              及び
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.PUBLIC_URL}/pdf/privacy-policy.pdf`}
              >
                プライバシーポリシー
              </a>
              を確認し、同意します
            </>
          }
        />
      </Stack>
      <Button
        variant="contained"
        disabled={
          !username ||
          !email ||
          !password ||
          !!passwordError ||
          !confirmPassword ||
          !!confirmPasswordError ||
          !acknowledgement
        }
        onClick={() => {
          const processId = addLoadingProcess();
          signUp({
            username,
            password,
            options: {
              userAttributes: {
                email,
                "custom:acknowledgement": process.env.REACT_APP_ACKNOWLEDGEMENT,
                "custom:deniedGenreIds": JSON.stringify(DefaultDeniedGenreIds),
              },
              autoSignIn: true,
            },
          })
            .then(({ isSignUpComplete, userId, nextStep }) => {
              const signinUsername = username;
              if (isSignUpComplete) {
                setUsername("");
                setEmail("");
                setPassword("");
                setConfirmPassword("");
                setAcknowledgement(false);
              }
              console.log(userId);
              switch (nextStep.signUpStep) {
                case "CONFIRM_SIGN_UP":
                  setSigninUsername(signinUsername);
                  setIsConfirmingSignUp(true);
                  alert("確認コードを入力しアカウント登録を完了してください");
                  break;
                case "COMPLETE_AUTO_SIGN_IN":
                  const process2Id = addLoadingProcess();
                  autoSignIn()
                    .catch((error) => {
                      handleError(error);
                    })
                    .finally(() => {
                      deleteLoadingProcess(process2Id);
                    });
                  break;
                default:
                  break;
              }
            })
            .catch((error) => {
              handleError(error);
            })
            .finally(() => {
              deleteLoadingProcess(processId);
            });
        }}
      >
        新規登録
      </Button>
    </Stack>
  );
};

export default SignUp;
