import { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import translate from "translate";

export const move = (navigate: NavigateFunction, to: string): void => {
  navigate(`${process.env.PUBLIC_URL}${to}`);
  window.scrollTo({
    top: 0,
    left: 0,
  });
};

export const handleError = (error: any): void => {
  console.log(error);
  if (error instanceof AxiosError && error.response) {
    alert(error.response.data);
  } else if (error instanceof Error) {
    translate(error.message, "ja")
      .then((translatedMessage) => {
        alert(translatedMessage);
      })
      .catch((err) => {
        alert(error.message);
        console.log(err);
      });
  } else if (typeof error === "string") {
    alert(error);
  } else {
    alert("エラーが発生しました");
  }
};

export const getDisplayedDatetime = (datetimeStr: string): string => {
  const datetime = new Date(datetimeStr);
  const diplayedDatetime = `${datetime.getFullYear()}/${(
    datetime.getMonth() + 1
  )
    .toString()
    .padStart(2, "0")}/${datetime
    .getDate()
    .toString()
    .padStart(2, "0")} ${datetime
    .getHours()
    .toString()
    .padStart(2, "0")}:${datetime.getMinutes().toString().padStart(2, "0")}`;
  return diplayedDatetime;
};

export const validatePassword = (password: string): string | undefined => {
  let result: string | undefined = undefined;
  if (password.length < 8) {
    result = "パスワードは8桁以上である必要があります";
  } else if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W])/g.test(password)) {
    result =
      "パスワードは小文字アルファベット・大文字アルファベット・数字・記号をすべて含む必要があります";
  }
  return result;
};
