import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Rating,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Context } from "../App";
import { AddBookEvent, SavedBook } from "../common/model";
import getAxiosInstance from "../common/Axios";
import { handleError, move } from "../common/utils";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import dialogScss from "../assets/scss/dialog.module.scss";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import commonScss from "../assets/scss/common.module.scss";
import { awsRum } from "..";

const BookDialog: React.FC = () => {
  const {
    bookDisplayInfo,
    setBookDisplayInfo,
    books,
    loadBooks,
    addLoadingProcess,
    deleteLoadingProcess,
  } = useContext(Context);
  const [savedBook, setSavedBook] = useState<SavedBook | null>(null);
  const navigate = useNavigate();

  const [memo, setMemo] = useState("");
  const [readAt, setReadAt] = useState<string | null>("");
  const [rating, setRating] = useState<number | null>(null);

  useEffect(() => {
    if (bookDisplayInfo) {
      books.every((book) => {
        const isDisplayedBook = book.isbnjan === bookDisplayInfo.isbnjan;
        if (isDisplayedBook) {
          setSavedBook(book);
        }
        return !isDisplayedBook;
      });
    } else {
      setSavedBook(null);
      setMemo("");
      setReadAt("");
      setRating(null);
    }
  }, [bookDisplayInfo, books]);

  useEffect(() => {
    if (savedBook) {
      setMemo(savedBook.memo || "");
      setReadAt(savedBook.readAt);
      setRating(savedBook.rating);
    }
  }, [savedBook]);

  const reset = (): void => {
    setBookDisplayInfo(null);
  };

  return (
    <Dialog open={!!bookDisplayInfo} fullWidth>
      {!!bookDisplayInfo && (
        <>
          <DialogTitle className={commonScss.textCenter}>
            {bookDisplayInfo.title}
          </DialogTitle>
          <IconButton
            onClick={() => {
              reset();
            }}
            className={dialogScss.close}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent>
            <Stack direction="column" alignItems="center" gap={2}>
              <img src={bookDisplayInfo.imageUrl} alt="" />
              <Stack direction="column" alignItems="center" gap={0.2}>
                <Button
                  color="primary"
                  href={bookDisplayInfo.itemUrl}
                  target="_blank"
                >
                  楽天ブックスで開く
                </Button>
                <Typography variant="caption">
                  ※アフィリエイトリンクです
                </Typography>
              </Stack>

              {!!bookDisplayInfo.author && (
                <span>{bookDisplayInfo.author}</span>
              )}
              {!!bookDisplayInfo.publisherName && (
                <span>{bookDisplayInfo.publisherName}</span>
              )}
              {(!savedBook || !savedBook?.isRead) && (
                <>
                  <DialogContentText>
                    {bookDisplayInfo.itemCaption}
                  </DialogContentText>
                </>
              )}
              {!!savedBook && savedBook.isRead && (
                <>
                  <TextField
                    value={memo}
                    onChange={(e) => {
                      setMemo(e.target.value);
                    }}
                    multiline
                    fullWidth
                    variant="filled"
                    placeholder="メモ、感想etc..."
                    rows={5}
                    inputProps={{
                      className: dialogScss.textarea,
                    }}
                  />
                  <DatePicker
                    label="読了日"
                    format="YYYY/MM/DD"
                    value={dayjs(readAt)}
                    onChange={(day) => {
                      if (day) {
                        setReadAt(
                          `${day.year()}-${(day.month() + 1)
                            .toString()
                            .padStart(2, "0")}-${day
                            .date()
                            .toString()
                            .padStart(2, "0")}`
                        );
                      }
                    }}
                  />
                  <Rating
                    value={rating}
                    onChange={(_, value) => {
                      setRating(value);
                    }}
                    max={6}
                  />
                </>
              )}
            </Stack>
          </DialogContent>
          <DialogActions>
            {!savedBook && (
              <>
                <Button
                  onClick={async () => {
                    const processId = addLoadingProcess();
                    const axiosInstance = await getAxiosInstance();
                    axiosInstance
                      .post("/books", {
                        isbnjan: bookDisplayInfo.isbnjan,
                        isRead: false,
                      })
                      .then(() => {
                        alert("本が読みたいリストに登録されました");
                        reset();
                        loadBooks();
                        if (awsRum) {
                          try {
                            const event: AddBookEvent = {
                              path: window.location.pathname,
                              isRead: false,
                            };
                            awsRum.recordEvent("addBook", event);
                          } catch (error) {
                            console.log(error);
                          }
                        }
                        move(navigate, "/");
                      })
                      .catch((error) => {
                        handleError(error);
                      })
                      .finally(() => {
                        deleteLoadingProcess(processId);
                      });
                  }}
                  color="secondary"
                >
                  読みたい
                </Button>
                <Button
                  onClick={async () => {
                    const processId = addLoadingProcess();
                    const axiosInstance = await getAxiosInstance();
                    axiosInstance
                      .post("/books", {
                        isbnjan: bookDisplayInfo.isbnjan,
                        isRead: true,
                      })
                      .then(() => {
                        alert("本が読了リストに登録されました");
                        reset();
                        loadBooks();
                        if (awsRum) {
                          try {
                            const event: AddBookEvent = {
                              path: window.location.pathname,
                              isRead: true,
                            };
                            awsRum.recordEvent("addBook", event);
                          } catch (error) {
                            console.log(error);
                          }
                        }
                        move(navigate, "/");
                      })
                      .catch((error) => {
                        handleError(error);
                      })
                      .finally(() => {
                        deleteLoadingProcess(processId);
                      });
                  }}
                  color="primary"
                >
                  読了
                </Button>
              </>
            )}
            {!!savedBook && (
              <>
                <Button
                  onClick={async () => {
                    if (
                      window.confirm(
                        "削除すると前の状態には戻せなくなります。実行しますか?"
                      )
                    ) {
                      const processId = addLoadingProcess();
                      const axiosInstance = await getAxiosInstance();
                      axiosInstance
                        .delete("/books", {
                          data: {
                            isbnjan: bookDisplayInfo.isbnjan,
                          },
                        })
                        .then(() => {
                          alert("削除しました");
                          reset();
                          loadBooks();
                          move(navigate, "/");
                        })
                        .catch((error) => {
                          handleError(error);
                        })
                        .finally(() => {
                          deleteLoadingProcess(processId);
                        });
                    }
                  }}
                  color="error"
                >
                  削除
                </Button>
                {savedBook.isRead && (
                  <>
                    <Button
                      onClick={async () => {
                        const processId = addLoadingProcess();
                        const axiosInstance = await getAxiosInstance();
                        axiosInstance
                          .put("/books", {
                            isbnjan: bookDisplayInfo.isbnjan,
                            isRead: savedBook.isRead,
                            memo,
                            readAt,
                            rating,
                          })
                          .then(() => {
                            alert("更新が完了しました");
                            reset();
                            loadBooks();
                            move(navigate, "/");
                          })
                          .catch((error) => {
                            handleError(error);
                          })
                          .finally(() => {
                            deleteLoadingProcess(processId);
                          });
                      }}
                    >
                      更新
                    </Button>
                  </>
                )}
                {!savedBook.isRead && (
                  <>
                    <Button
                      onClick={async () => {
                        const processId = addLoadingProcess();
                        const axiosInstance = await getAxiosInstance();
                        axiosInstance
                          .put("/books", {
                            isbnjan: bookDisplayInfo.isbnjan,
                            isRead: true,
                            memo: "",
                            readAt: savedBook.readAt,
                            rating: null,
                          })
                          .then(() => {
                            alert("読了リストに移動しました");
                            reset();
                            loadBooks();
                            move(navigate, "/");
                          })
                          .catch((error) => {
                            handleError(error);
                          })
                          .finally(() => {
                            deleteLoadingProcess(processId);
                          });
                      }}
                      color="primary"
                    >
                      読了
                    </Button>
                  </>
                )}
              </>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};

export default BookDialog;
