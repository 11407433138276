import React, { useEffect, useState } from "react";
import CommonProps from "./CommonProps";
import { AccordionDetails } from "@mui/material";

interface Props extends CommonProps {
  content: React.ReactNode;
}

const Details: React.FC<Props> = (props) => {
  const { userAttributes, isSet, content } = props;

  const [showable, setShowable] = useState(false);
  useEffect(() => {
    setShowable(!!userAttributes && isSet);
  }, [userAttributes, isSet]);

  return (
    <AccordionDetails>
      {!showable && "ユーザー情報取得中あるいはエラー発生..."}
      {showable && content}
    </AccordionDetails>
  );
};

export default Details;
