import React, { useContext } from "react";
import Header from "./components/Header";
import { Routes, Route } from "react-router-dom";
import CommonGrid from "./components/CommonGrid";
import Search from "./pages/Search";
import BookDialog from "./components/BookDialog";
import Bookshelf from "./pages/Bookshelf";
import Account from "./pages/Account";
import Acknowledgement from "./components/Acknowledgement";
import Recommended from "./pages/Recommended";
import Messages from "./pages/Messages";
import Auth from "./components/auth/Auth";
import { Context } from "./App";
import PersonalizationSettingDialog from "./components/PersonalizationSettingDialog";
import Info from "./components/Info";
import Introduction from "./components/Introduction";

const Content: React.FC = () => {
  const { user, isConfirmingSignUp, isResettingPassword } = useContext(Context);
  return (
    <CommonGrid>
      <main>
        <Header />
        {!!user && !isConfirmingSignUp && !isResettingPassword && (
          <div>
            <Routes>
              <Route
                path={`${process.env.PUBLIC_URL}/search`}
                element={<Search />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/account`}
                element={<Account />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/messages`}
                element={<Messages />}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/recommended`}
                element={<Recommended />}
              />
              <Route path={"/*"} element={<Bookshelf />} />
            </Routes>
            <BookDialog />
            <Acknowledgement />
            <PersonalizationSettingDialog />
          </div>
        )}
        <Auth />
        <Introduction />
        <Info />
      </main>
    </CommonGrid>
  );
};

export default Content;
